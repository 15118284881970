import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {branchDict} from "./config.js"
import {BrowserView, MobileView} from 'react-device-detect'
import {FaAngleDown, FaAngleUp} from "react-icons/fa";

const Infos = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [room, setRoom] = useState("1");

    const [agreeAll, setAgreeAll] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);

    const [showAgree1, setShowAgree1] = useState(false);
    const [showAgree2, setShowAgree2] = useState(false);

    const clickAgreeAll = () => {
        if (agreeAll) {
            setAgreeAll(false);
            setAgree1(false);
            setAgree2(false)
        } else {
            setAgreeAll(true);
            setAgree1(true);
            setAgree2(true)
        }
    }
    const clickAgree1 = () => {
        setAgree1(prev => !prev)
    }
    const clickAgree2 = () => {
        setAgree2(prev => !prev)
    }

    const handleShowAgree1 = () => {
        setShowAgree1(prev => !prev)
    }
    const handleShowAgree2 = () => {
        setShowAgree2(prev => !prev)
    }


    const location = useLocation();
    console.log(location)
    const [data, setData] = useState(location.state);
    console.log(data)
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const today = new Date();
    const [date, setDate] = useState(null);
    const [hourNow, setHourNow] = useState(today.getHours());
    const [hour, setHour] = useState(today.getHours());
    const [day, setDay] = useState(0);
    const [select, setSelect] = useState([]);
    const [reserved, setReserved] = useState([]);
    const [branch, setBranch] = useState("");

//    const [history, setHistory] = useState([]);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [step, setStep] = useState(2);
    const [price, setPrice] = useState(null);
    const [roomList, setRoomList] = useState(["6", "4", "2", "5", "3", "7", "8", "1", "9"]);
    const [numList, setNumList] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9"]);

    useEffect(() => {
        if (data == null) {
            prevStep()
            window.alert("비정상적인 접근입니다. 처음부터 예약을 진행해주세요.")
            navigate('/book', {state: {branch: branch}})
        } else {
            setName(data.name)
            setEmail(data.email)
            setPhone(data.phone)
            setDate(data.date)
            setStart(data.start)
            setEnd(data.end)
            setStartTime(data.startTime)
            setEndTime(data.endTime)
            setPrice(data.price)
            setOpen(true)
            setBranch(data.branch)
        }
    }, [])


    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        console.log("ROOM::", event.target.id);
        setRoom(event.target.id);
        setShow(true);
    };


    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const prevStep = (event) => {
        fetch(process.env.REACT_APP_serviceUrl + "/reservation"//stgUrl + "/reservation"
            , {
                credentials: 'include', method: "DELETE", headers: {
                    "Content-Type": "application/json", //                  "Access-Control-Allow-Methods" : "GET, POST, OPTIONS, PUT, DELETE",
//                  "Access-Control-Allow-Origin" : "*",
//                  "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept, Authorization"
                },
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status == "success") {
                    navigate('/room', {state: {branch: branch}})
//                    setStep(prev => prev-1)
                } else {
                    window.alert(json.message)
                    navigate('/room', {state: {branch: branch}})
                }

            })

    }

    const payStep = (event) => {

        if (price == 0) {
            window.alert("방과 예약시간을 다시 선택해주세요.")
            navigate('/room', {state: {branch: branch}})
        } else if (name == "") {
            window.alert("이름을 입력해주세요.")
        } else if (name.length < 2) {
            window.alert("이름은 한글자이상 입력해주세요.")
        } else if (email.length > 0 && (email.length < 5 || email.includes("@") == false || email.includes(".") == false)) {
            window.alert("정상적인 이메일을 입력해주세요")
        } else if (phone == "") {
            window.alert("휴대폰 번호를 입력해주세요.")
        } else if (phone.length < 10) {
            console.log(phone)
            window.alert("정상적인 휴대폰 번호를 입력해주세요.")
        } else if (agree1 == false) {
            console.log(agree1)
            window.alert("환불 규정을 확인후 동의해주세요.")
        } else if (agree2 == false) {
            console.log(agree1)
            window.alert("개인정보 수집 및 이용동의 규정을 확인후 동의해주세요.")
        } else if (email == "") {
            navigate('/pay', {
                state: {
//                      orderId : json.data.orderId,
                    start: start,
                    end: end,
                    date: date,
                    name: name,
                    email: null,
                    phone: phone,
                    startTime: startTime,
                    endTime: endTime,
                    price: price,
                    branch: branch,
                }
            })

            console.log({
//                      orderId : json.data.orderId,
                start: start,
                end: end,
                date: date,
                name: name,
                email: email,
                phone: phone,
                startTime: startTime,
                endTime: endTime,
                price: price,
                branch: branch,
            })
        } else {
            navigate('/pay', {
                state: {
//                      orderId : json.data.orderId,
                    start: start,
                    end: end,
                    date: date,
                    name: name,
                    email: email,
                    phone: phone,
                    startTime: startTime,
                    endTime: endTime,
                    price: price,
                    branch: branch,
                }
            })

            console.log({
//                      orderId : json.data.orderId,
                start: start,
                end: end,
                date: date,
                name: name,
                email: email,
                phone: phone,
                startTime: startTime,
                endTime: endTime,
                price: price,
                branch: branch,
            })
        }
    }

    return (<>
        <div className="mypage-wrap">
            <div className="inner">
                <BrowserView>
                    <div className={step >= 0 ? "rectangle left on" : "rectangle left"} id="1"> STEP 1 : 지점 선택</div>
                    <div className={step >= 1 ? "rectangle on" : "rectangle"} id="2"> STEP 2 : 방 선택</div>
                    <div className={step >= 2 ? "rectangle on" : "rectangle"} id="3"> STEP 3 : 예약 정보</div>
                    <div className={step >= 3 ? "rectangle on" : "rectangle"} id="4"> STEP 4 : 요금 결제</div>
                    <div className={step >= 4 ? "rectangle right on" : "rectangle right"} id="5"> STEP 5 : 예약완료</div>
                </BrowserView>
                <MobileView>
                    <div className={step >= 0 ? "rectangle left on" : "rectangle left"} id="1"> 1. 지점선택</div>
                    <div className={step >= 1 ? "rectangle on" : "rectangle"} id="2"> 2. 방선택</div>
                    <div className={step >= 2 ? "rectangle on" : "rectangle"} id="3"> 3. 정보</div>
                    <div className={step >= 3 ? "rectangle on" : "rectangle"} id="4"> 4. 결제</div>
                    <div className={step >= 4 ? "rectangle right on" : "rectangle right"} id="5"> 5. 완료</div>
                </MobileView>

                <div>
                    <div className="form-box">
                        {!open ?
                            <div / >
                            :
                            <>
                                <div className="form-group">
                                    <span className="tip title">예약 정보</span>
                                    <div>
                                        예약장소 : <span
                                        className="calc highlight">{start == "9_9_9" ? "" : (branchDict[branch] + " - Room " + start.split("_")[0])}</span>
                                    </div>
                                    <div>
                                        예약일시 : {start == "9_9_9" ? <span/>
                                                                    :
                                                                    <span className="calc highlight">2024년 {data.startTime.slice(4, 6)}월 {data.startTime.slice(6, 8)}일 {data.startTime.slice(8, 10)}:{data.startTime.slice(10, 12)} ~ 2024년 {data.endTime.slice(4, 6)}월 {data.endTime.slice(6, 8)}일 {(parseInt(data.endTime.slice(10, 12)) + 10) == 60 ? (parseInt(data.endTime.slice(8, 10)) + 1).toString() : data.endTime.slice(8, 10)}:{(parseInt(data.endTime.slice(10, 12)) + 10) == 60 ? "00" : (parseInt(data.endTime.slice(10, 12)) + 10).toString()}</span>
                                                    }
                                        <div>
                                        </div>
                                        금액 : <span
                                        className="calc highlight">{price < 0 ? "0" : addComma(price)}원</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <span className="tip title">예약자 정보</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name" className="label"><span
                                        className="color-false">* </span>이름</label>
                                    <input className="form-control" type="text" id="name" placeholder="이름을 입력해주세요."
                                           value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tel2" className="label"><span className="color-false">* </span> 전화번호</label>
                                    {/*<div className="form-with-btn">
                                        <input className="form-control" type="text" id="tel2" placeholder="010-7748-0152" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                        {/*<button className="btn-send" type="button">인증번호 보내기</button>
                                    </div>*/}
                                    <input className="form-control" type="text" id="tel"
                                           value={phone.length > 7 ? phone.slice(0, 3) + "-" + phone.slice(3, 7) + "-" + phone.slice(7,) : phone.length > 3 ? phone.slice(0, 3) + "-" + phone.slice(3,) : phone}
                                           onChange={(e) => setPhone(e.target.value.toString().replaceAll("-", ""))}
                                           placeholder="전화번호를 입력해 주세요"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="id" className="label">이메일</label>
                                    <div className="tip">* 이메일 입력시 출입문 링크가 카카오톡 뿐만 아니라 이메일로도 발송이됩니다.</div>
                                    <input className="form-control" type="text" id="id" placeholder="이메일을 입력해주세요."
                                           value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                            </>}
                            {/*<div className="form-group">
                                <span className="tip title">환불 규정</span>
                                <span className="tip">예약시간 <span className="tip bold">30분전 </span>까지 : <span className="tip important">100% 환불 </span></span>
                                <span className="tip">예약시간 <span className="tip bold">30분전 </span>이후 :  <span className="tip important">환불 불가 </span> </span>
                                <span className="tip">(단, 고장이나 기타 판매자의 과실로 인해 서비스를 정상적으로 받으실 수 없는 경우에는 전액 환불하여 드립니다.) </span>
                            </div>*/}
                            <div className="form-group">
                                <span className="tip title">이용 약관</span>
                            </div>
                            <div className="form-group">
                                {/*<div className="title">약관확인</div>
                                <div className="desc">
                                    약관에 동의하신 후 해당 서비스를 이용하실 수 있습니다.
                                </div>*/}
                                <div>
                                    <input type="checkbox" className="check-agree" checked={agreeAll}
                                           onClick={clickAgreeAll}/>
                                    <span className="font-18-with-check">전체 동의</span>
                                    {/*<label className="label detail" onClick={handleShow} >
                                        내용확인하기
                                    </label>*/}
                                    <hr className="line"/>
                                </div>
                                <div>
                                    <input type="checkbox" className="check-agree depth-1" checked={agree1}
                                           onClick={clickAgree1}/>
                                    <span className="font-17-with-check" onClick={handleShowAgree1}>환불 규정 (필수)</span>
                                    {/*<label className="label detail" onClick={handleShow} >
                                        내용확인하기
                                    </label>*/}
                                    {showAgree1 == true ? <FaAngleUp onClick={handleShowAgree1}/> :
                                        <FaAngleDown onClick={handleShowAgree1}/>}
                                    {showAgree1 == true ? <div className="content-agree">
                                        {/*<p>[환불규정] </p>*/}
                                        <p>결제 후 아래의 규정에 맞춰 환불이 가능합니다. </p>
                                        <table className="table-agree">
                                            <thead>
                                            <tr className="tr-agree">
                                                <th className="th-agree">환불 요청 시점</th>
                                                <th className="th-agree">환불 금액</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="tr-agree">
                                                <td className="td-agree">예약시작 시간 <a className="tip bold">30분</a> 전까지
                                                </td>
                                                <td className="td-agree"><span className="tip important">100% 환불</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tbody>
                                            <tr className="tr-agree">
                                                <td className="td-agree">예약시작 시간 <a className="tip bold">30분</a> 이후</td>
                                                <td className="td-agree"><span className="tip important">환불 불가</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <p>서비스 특성상 시작시간 30분 이내 취소시 다른 고객이 원활하게 예약하기 어려우므로 환불이 어렵습니다.</p>
                                        <p>위와 같은 환불정책에 동의하십니까?</p>
                                    </div> : <div/>}
                                    <hr className="line left-50"/>
                                </div>
                                <div>
                                    <input type="checkbox" className="check-agree depth-1" checked={agree2}
                                           onClick={clickAgree2}/>
                                    <span className="font-17-with-check"
                                          onClick={handleShowAgree2}>개인정보수집 및 이용동의 (필수)</span>
                                    {/*<label className="label detail" onClick={handleShow} >
                                        내용확인하기
                                    </label>*/}
                                    {showAgree2 == true ?
                                        <FaAngleUp onClick={handleShowAgree2}/>
                                        :
                                        <FaAngleDown onClick={handleShowAgree2}/>
                                    }
                                    {showAgree2 == true ?
                                        <div className="content-agree">
                                            {/*<p>[개인정보 수집이용 동의서]</p>*/}
                                            <p>나비잠 안마의자는 아래의 목적으로 개인정보를 수집∙이용하며, 회원의 소중한 개인정보를 보호함으로써 안심하고 서비스를 이용할 수 있도록
                                                최선을 다합니다. </p>
                                            <table className="table-agree">
                                                <thead>
                                                <tr className="tr-agree">
                                                    <th className="th-agree">서비스</th>
                                                    <th className="th-agree">수집 목적</th>
                                                    <th className="th-agree">수집 항목</th>
                                                    <th className="th-agree">보유 및 이용기간</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className="tr-agree">
                                                    <td className="td-agree">예약 서비스</td>
                                                    <td className="td-agree">예약/결제 후 정보 전달을 위한 개인정보 수집</td>
                                                    <td className="td-agree"><span
                                                        className="tip bold">성명, 전화번호, 이메일주소(선택)</span></td>
                                                    <td className="td-agree">수집일로부터 <span className="tip bold">5년</span>
                                                        <p>※단, 관계법령 위반에 따른 수사/조사 등이 진행 중인 경우, 해당 수사/조사 종료시까지</p></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <p>귀하는 위의 개인정보 수집∙이용에 대해 동의를 거부할 권리가 있습니다. 선택정보를 입력하지 않을 경우 귀하에게 맞는 서비스 제공이 어려울
                                                수 있으나 서비스 이용 제한은 없습니다. </p>
                                            <p>위와 같이 개인정보를 수집∙이용하는 것에 동의하십니까?</p>
                                        </div>
                                        :
                                        <div/>
                                    }
                                    <hr className="line left-50"/>
                                </div>

                            </div>

                            {/*<div className="form-group">
                                <label htmlFor="number" className="label">인증번호</label>
                                <div className="form-with-btn">
                                    <input className="form-control" type="text" id="number" placeholder="인증번호를 입력해 주세요"/>
                                    <button className="btn-send color-black" type="button">02:00</button>
                                </div>
                            </div>*/}
                            <div>
                                <BrowserView>
                                    {/*<div className="btn-wrap">
                                      <button className="btn btn-black left" onClick={prevStep}>이전단계</button>*/}
                                    <button className="btn btn-black right" onClick={payStep}>다음으로</button>
                                    <div className="txt-underline" onClick={prevStep}>
                                        이전단계로
                                    </div>
                                    {/*} </div>*/}
                                </BrowserView>
                                <MobileView>
                                    {/*<button className="btn btn-black left prev" onClick={prevStep}>이전단계</button>*/}
                                    <button className="btn btn-black right" onClick={payStep}>다음으로</button>
                                    <div className="txt-underline" onClick={prevStep}>
                                        이전단계로
                                    </div>
                                </MobileView>
                            </div>
                        </div>
                            </div>
                            </div>
                            </div>
                            </>
                            );};
export default Infos;


