import React, {useState} from 'react';
import Header from '../Components/Header';
import MobileMenu from "../Components/MobileMenu";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import ExplainAddress from "../Pages/ExplainAddress";

// df
const ExplainAddr = () => {
    const [category, setCategory] = useState("now");
    const [property, setProperty] = useState(null);
    const [message, setMessage] = useState(null);


    return (<>
            <Header/>
            <div className="main-wrap">
                <ExplainAddress/>
                {/*<Tabs
                    defaultActiveKey="_"
                    id="main"
                    onSelect={(k) => window.location.href="/"+k}
                >
                    <Tab eventKey="" title="소개">
                        <ExplainAddress/>
                    </Tab>
                    <Tab eventKey="book" title="예약/결제" >
                        <Booking/>
                    </Tab>
                    <Tab eventKey="check" title="조회">
                        <Checking/>
                    </Tab>
                    <Tab eventKey="qna" title="문의">
                        <QnAs />
                    </Tab>

                </Tabs>*/}
            </div>
            <MobileMenu/>
        </>);
};

export default ExplainAddr;
