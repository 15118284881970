import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Footer from './Components/Footer';
import Intro from "./Pages/Intro";
import Complete from "./Pages/Complete";
import Fail from "./Pages/Fail";
import Process from "./Pages/Process";
import Booking from "./Pages/Booking";
import Paying from "./Pages/Paying";
import Check from "./Pages/Check";
import QnA from "./Pages/QnA";
import Main from './Pages/Main';
import Book from "./Pages/Book";
import BookRoom from "./Pages/BookRoom";
import Info from "./Pages/Info";
import Pay from "./Pages/Pay";
import ExplainAddr from "./Pages/ExplainAddr";
import Recommend from "./Pages/Recommend";

import AdminLogin from "./Pages/admin/AdminLogin";
import AdminBook from "./Pages/admin/AdminBook";
import AdminInfo from "./Pages/admin/AdminInfo";

import { Link } from 'react-router-dom';
import './Style/bootstrap-custom.scss';
import './Style/style.scss';


{/*
import LoginExist from './Pages/saved/LoginExist';
import Start from './Pages/saved/Start';
import Detail from './Pages/saved/Detail';
import DetailLaw from './Pages/saved/DetailLaw';
import SearchResult from './Pages/saved/SearchResult';
import CompleteSignIn from "./Pages/saved/CompleteSignIn";
import SignIn from "./Pages/saved/SignIn";
import FindAccount from "./Pages/saved/FindAccount";
import ResetPassword from "./Pages/saved/ResetPassword";
import Monitoring from "./Pages/saved/Monitoring";
import Mypage from "./Pages/saved/Mypage";
import ChangeInfo from "./Pages/ChangeInfo";
import MyBiddingList from "./Pages/saved/MyBiddingList";
import MyProperty from "./Pages/saved/MyProperty";
import MyFavorites from "./Pages/saved/MyFavorites";
import AddListing from "./Pages/saved/AddListing";
import AddListingNiceAPI from "./Pages/saved/AddListingNiceAPI";*/}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' exact element={<Main/>}/>
                <Route path='/book' exact element={<Book/>}/>
                <Route path='/room' exact element={<BookRoom/>}/>
                <Route path='/info' exact element={<Info/>}/>
                <Route path='/pay' exact element={<Pay/>}/>
                <Route path='/check' exact element={<Check/>}/>
                <Route path='/qna' exact element={<QnA/>}/>
                <Route path='/intro' exact element={<Intro/>}/>
                <Route path='/process' exact element={<Process/>}/>
                <Route path='/complete' exact element={<Complete/>}/>
                <Route path='/fail' exact element={<Fail/>}/>
                <Route path='/explain-addr' exact element={<ExplainAddr/>}/>
                <Route path='/booking' exact element={<Booking/>}/>
                <Route path='/recommend' exact element={<Recommend/>}/>

                <Route path='/admin' exact element={<AdminLogin/>}/>
                <Route path='/admin/book' exact element={<AdminBook/>}/>
                <Route path='/admin/info' exact element={<AdminInfo/>}/>

                {/*<Route path='/change-info' exact element={<ChangeInfo/>}/>
                <Route path='/result' exact element={<SearchResult/>}/>
                <Route path='/login' exact element={<Login/>}/>
                <Route path='/login-exist' exact element={<LoginExist/>}/>
                <Route path='/start' exact element={<Start/>}/>
                <Route path='/detail' exact element={<Detail/>}/>
                <Route path='/detail-law' exact element={<DetailLaw/>}/>
                <Route path='/sign-in' exact element={<SignIn/>}/>
                <Route path='/complete-sign-in' exact element={<CompleteSignIn/>}/>
                <Route path='/find-account' exact element={<FindAccount/>}/>
                <Route path='/reset-password' exact element={<ResetPassword/>}/>
                <Route path='/monitoring' exact element={<Monitoring/>}/>
                <Route path='/Mypage' exact element={<Mypage/>}/>
                <Route path='/change-info' exact element={<ChangeInfo/>}/>
                <Route path='/bidding-list' exact element={<MyBiddingList/>}/>
                <Route path='/my-property' exact element={<MyProperty/>}/>
                <Route path='/my-favorite' exact element={<MyFavorites/>}/>
                <Route path='/add-listing' exact element={<AddListing/>}/>
                <Route path='/add-listing-nice-api' exact element={<AddListingNiceAPI/>}/>
                <Route path='/event-detail' exact element={<EventDetail/>}/>
                <Route path='/complete-nice-api' exact element={<CompleteNiceAPI/>}/>*/}
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
