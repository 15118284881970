import MobileDetailMenu from "../Components/MobileDetailMenu";
import React, {useEffect, useState} from 'react';
import {BrowserView, MobileView} from 'react-device-detect'
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {branchAddress, branchDict, branchList, numList, RoomExplain, roomImgDict, RoomName, RoomRank} from "./config.js"
import {TbStar, TbStarHalf} from "react-icons/tb";


const Intro = () => {

    const [tab, setTab] = useState("나비잠 소개");
    const [room, setRoom] = useState("1");
    const [branch, setBranch] = useState("Gwanghwamun");
    const [roomImg, setRoomImg] = useState("제스파_루넉스.png");
    const handleTab = (e) => {
        setTab(e.target.id)
    }
    const handleRoom = (e) => {
        setRoom(e.target.id)
    }
    useEffect(() => {
        setRoomImg(roomImgDict[branch]["Room " + room])
    }, [room, branch])

    const addRoom = (e) => {
        if (room == "9") {
            setRoom("1")
        } else {
            setRoom(prev => ((parseInt(prev) + 1).toString()))
        }
    }
    const subRoom = (e) => {
        if (room == "1") {
            setRoom("9")
        } else {
            setRoom(prev => ((parseInt(prev) - 1).toString()))
        }
    }
    return (<>
            <div className="intro-wrap">

                <BrowserView>
                    <div className="visual">
                        <img src={process.env.PUBLIC_URL + '/img/logo/logo_img.jpeg'} alt="나비잠 안마의자"/>
                    </div>
                    <div className={tab == "나비잠 소개" ? "line-rec up on" : "line-rec up"} id="나비잠 소개"
                         onClick={handleTab}> 나비잠 안마의자 소개
                    </div>
                    <div className={tab == "지점 소개" ? "line-rec up on" : "line-rec up"} id="지점 소개"
                         onClick={handleTab}> 지점 소개
                    </div>
                </BrowserView>

                <MobileView>
                    <div className={tab == "나비잠 소개" ? "line-rec-mobile up on" : "line-rec-mobile up"} id="나비잠 소개"
                         onClick={handleTab}> 나비잠 소개
                    </div>
                    <div className={tab == "지점 소개" ? "line-rec-mobile up on" : "line-rec-mobile up"} id="지점 소개"
                         onClick={handleTab}>지점 소개
                    </div>
                </MobileView>

                <hr className="line"/>
                {tab == "나비잠 소개" ? <div className="inner">
                    <div className="img-row">
                        <MobileView>
                            <div className="img-row">
                                <div className="img-sm-mobile">
                                    <img src={process.env.PUBLIC_URL + '/img/logo/logo_illust_v3.png'}
                                         alt="나비잠 소개 대표이미지"/>
                                </div>
                            </div>
                            {/*<div className="img-row">*/}
                            <div className="txt-box">
                                <div className="txt-lg">언제든 쉴 수 있는 24-hour 무인 안마의자</div>
                                <div className="desc right">전날 술먹고 출근했을때, 운동을 너무 심하게 한날, 약속이 끝나고 유독 피곤한 날 있지 않으신가요?
                                </div>
                                <div className="desc right">이런 날을 위해 잠깐 몸을 편히 눕힐 수 있는 공간을 만들었습니다.</div>
                                <div className="desc right">1인실로 구분된 방안에서 안마의자를 마음껏 즐기시고, 에너지를 충전해보세요.</div>
                                <div className="desc right">언제든 편하게 예약하시고 마음껏 사용해주시고, 불편한 점도 언제든 말씀해주세요 :)</div>
                            </div>
                            {/*</div>*/}
                            <div className="img-row">
                                <div className="img-sm-mobile">
                                    <img src={process.env.PUBLIC_URL + '/img/logo/logo_illust_v2.png'}
                                         alt="나비잠 소개 대표이미지"/>
                                </div>
                            </div>
                            <div className="txt-box">
                                <div className="txt-lg">피로회복을 위한 최적의 공간</div>
                                <div className="desc right">- 파나소닉, 바디프랜드, 제스파, 코지마, OSIM 등 최고급 안마의자들만 비치했습니다.</div>
                                <div className="desc right">- 1인실로 구성되어 있는 공간을 통해 휴식에 집중할 수 있게 했습니다.</div>
                                <div className="desc right">- 각 방마다 각기 다른 안마의자를 비치해두어 다양하게 경험해보실 수 있습니다.</div>
                                <div className="desc right">- 산소발생기와 공기청정기를 통해 맑은 공기를 제공합니다.</div>
                                <div className="desc right">- 안대와 각방 개별 조명을 통해 수면도 가능한 환경을 제공합니다.</div>
                            </div>

                        </MobileView>
                        <BrowserView>
                            <div className="img-row">
                                <div className="img-sm width">
                                    {/* <img src={process.env.PUBLIC_URL + '/img/store/가게전면.webp'} alt="나비잠 소개 대표이미지"/> */}
                                    <img src={process.env.PUBLIC_URL + '/img/logo/logo_illust_v3.png'}
                                         alt="나비잠 소개 대표이미지"/>
                                </div>
                                <div className="txt-box">
                                    <div className="txt-lg right">언제든 쉴 수 있는 24-hour 무인 안마의자</div>
                                    <div className="desc">전날 술먹고 출근했을때, 운동을 너무 심하게 한날, 약속이 끝나고 유독 피곤한 날 있지 않으신가요?</div>
                                    <div className="desc">이런 날을 위해 잠깐 몸을 편히 눕힐 수 있는 공간을 만들었습니다.</div>
                                    <div className="desc">1인실로 구분된 방안에서 안마의자를 마음껏 즐기시고, 에너지를 충전해보세요.</div>
                                    <div className="desc">언제든 편하게 예약하시고 마음껏 사용해주시고, 불편한 점도 언제든 말씀해주세요 :)</div>
                                </div>
                            </div>
                            <div className="img-row">
                                <div className="txt-box">
                                    <div className="txt-lg right">피로회복을 위한 최적의 공간</div>
                                    <div className="desc">- 파나소닉, 바디프랜드, 제스파, 코지마, OSIM 등 최고급 안마의자들만 비치했습니다.</div>
                                    <div className="desc">- 1인실로 구성되어 있는 공간을 통해 휴식에 집중할 수 있게 했습니다.</div>
                                    <div className="desc">- 각 방마다 각기 다른 안마의자를 비치해두어 다양하게 경험해보실 수 있습니다.</div>
                                    <div className="desc">- 산소발생기와 공기청정기를 통해 맑은 공기를 제공합니다.</div>
                                    <div className="desc">- 안대와 각방 개별 조명을 통해 수면도 가능한 환경을 제공합니다.</div>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + '/img/logo/logo_illust_v2.png'}
                                         alt="나비잠 소개 대표이미지"/>
                                </div>
                            </div>


                            <div className="img-row">
                                <div className="txt-box">
                                    <div className="txt-lg">편의시설 소개</div>
                                </div>
                            </div>

                            <div className="img-col">
                                <div className="img-row">
                                    <div className="img-sm width">
                                        <img src={process.env.PUBLIC_URL + "/img/facility/각방조명.webp"} alt="각 안마의자 이미지"/>
                                        <div className="txt-box">
                                            <div className="desc right">- 수면에 적합하도록 각 방에 조명을 개별로 설치하였습니다.</div>
                                        </div>
                                    </div>
                                    <div className="img-sm width">
                                        <img src={process.env.PUBLIC_URL + "/img/facility/안대.webp"} alt="각 안마의자 이미지"/>
                                        <div className="txt-box">
                                            <div className="desc right">- 수면시 사용하실 수 있는 안대를 각 방에 준비했습니다.</div>
                                        </div>
                                    </div>
                                    <div className="img-sm width">
                                        <img src={process.env.PUBLIC_URL + "/img/facility/공기청정기.webp"}
                                             alt="각 안마의자 이미지"/>
                                        <div className="txt-box">
                                            <div className="desc right">- 공기청정기를 통해 맑고 깨끗한 공기를 제공합니다.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="img-row">
                                    <div className="img-sm width">
                                        <img src={process.env.PUBLIC_URL + "/img/facility/산소발생기.webp"}
                                             alt="각 안마의자 이미지"/>
                                        <div className="txt-box">
                                            <div className="desc right">- 산소발생기와 디퓨저로 산소가득 쾌적한 공기를 제공합니다.</div>
                                        </div>
                                    </div>
                                    <div className="img-sm width">
                                        <img src={process.env.PUBLIC_URL + "/img/facility/충전기.webp"} alt="각 안마의자 이미지"/>
                                        <div className="txt-box">
                                            <div className="desc right">- 편의를 위해 각 방에 충전기가 구비되어있습니다.</div>
                                        </div>
                                    </div>
                                    <div className="img-sm width">
                                        <img src={process.env.PUBLIC_URL + "/img/facility/소화기.webp"} alt="각 안마의자 이미지"/>
                                        <div className="txt-box">
                                            <div className="desc right">- 안전을 위해 각 방과 복도에 소화기가 구비되어 있습니다.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/*<div className="img-row">
                                <div className="txt-box">
                                    <div className="txt-lg">오시는 길</div>
                                </div>
                             </div>
                             <div className="txt-box">
                                <div className="txt-md">르메이에르 종로 지하2층 올라가는 방향 에스컬레이터 옆</div>
                                <div className="desc">서울 종로구 종로 19 르메이에르종로타운 지하 2층</div>
                            </div>

                            <div className="img-row">
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + '/img/store/가게전면_위.webp'} alt="헤드텍스트가 들어갑니다"/>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + '/img/store/가게측면.webp'} alt="헤드텍스트가 들어갑니다"/>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + '/img/store/가게내부.webp'} alt="헤드텍스트가 들어갑니다"/>
                                </div>
                            </div>*/}
                        </BrowserView>
                    </div>
                </div> : <div className="inner">
                    <BrowserView>
                        <div className="time">
                            {branchList.map((b, i) => <div className={branch == b ? "rec-branch on" : "rec-branch"}
                                                           id={b} onClick={() => {
                                setBranch(b);
                                setRoom("1")
                            }}> {branchDict[b]} </div>)}
                        </div>

                        {/*<div>
                            <div className={branch=="Gwanghwamun"?"rec-branch on":"rec-branch"} id="Gwanghwamun"onClick={()=>{setBranch("Gwanghwamun"); setRoom("1")}}>광화문점</div>
                            <div className={branch=="Yeouido"?"rec-branch on":"rec-branch"} id="Yeouido" onClick={()=>{setBranch("Yeouido"); setRoom("1")}}>여의도점</div>
                            <div className={branch=="Ku"?"rec-branch on":"rec-branch"} id="Ku" onClick={()=>{setBranch("Ku"); setRoom("1")}}>고대안암점</div>
                            <div className={branch=="Yeoksam"?"rec-branch on":"rec-branch"} id="Yeoksam" onClick={()=>{setBranch("Yeoksam"); setRoom("1")}}>역삼점</div>
                        </div>*/}

                        <div className="img-row">
                            {/*<div className="txt-box">
                                <div className="txt-lg">오시는 길</div>
                            </div>*/}
                        </div>
                        <div className="txt-box">
                            <div className="txt-md">{branchAddress[branch][0]}</div>
                            <div className="desc">{branchAddress[branch][1]}</div>
                        </div>

                        <div className="img-row">
                            <div className="img-sm margin"/>
                            <div className="img-sm width">
                                <img src={process.env.PUBLIC_URL + '/img/store/' + branch + '/가게전면_위.webp'}
                                     alt="헤드텍스트가 들어갑니다"/>
                            </div>
                            <div className="img-sm width">
                                <img src={process.env.PUBLIC_URL + '/img/store/' + branch + '/가게측면.webp'}
                                     alt="헤드텍스트가 들어갑니다"/>
                            </div>
                            <div className="img-sm width">
                                <img src={process.env.PUBLIC_URL + '/img/store/' + branch + '/가게내부.webp'}
                                     alt="헤드텍스트가 들어갑니다"/>
                            </div>
                        </div>
                        <div className="txt-box">
                            <div className="txt-md">안마의자 소개</div>
                        </div>
                        <div className="indent">
                            {numList[branch].map((n, i) => <div className={room == n ? "room-rec on" : "room-rec"}
                                                                id={n} onClick={handleRoom}>{"ROOM " + n}</div>)}
                        </div>
                        <div className="indent">
                            <div className="img-row">
                                <div className="img-sm width-50">
                                    <img src={process.env.PUBLIC_URL + "/img/chair/" + branch + "/" + roomImg}
                                         alt="각 안마의자 이미지"/>
                                </div>
                                <div className="txt-box width-50">
                                    <div className="form-group">
                                        <label htmlFor="id"
                                               className={"rec-label " + RoomRank[branch][room]}>{RoomRank[branch][room]} </label>
                                    </div>

                                    <div className="txt-md">{RoomName[branch][room]}</div>
                                    <div className="val">
                                        <div className="desc">
                                            마사지 세기 :
                                            {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["strength"])))]).map((n, i) =>
                                                <TbStar color="FFB137"/>)}
                                            {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["strength"] % 1)))]).map((n, i) =>
                                                <TbStarHalf color="FFB137"/>)}
                                            {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                        </div>
                                        <div className="desc">
                                            안마의자 크기 :
                                            {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["size"])))]).map((n, i) =>
                                                <TbStar color="FFB137"/>)}
                                            {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["size"] % 1)))]).map((n, i) =>
                                                <TbStarHalf color="FFB137"/>)}
                                            {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                        </div>
                                    </div>
                                    {(RoomExplain[branch][room]["etc"]).map((item, idx) => {
                                        return <div className="desc">- {item}</div>
                                    })}
                                    <div className="time">
                                        {(RoomExplain[branch][room]["mode"]).map((item, idx) => {
                                            return (item[0]) == "*" ? <><br/>
                                                <div className={"room-rec on"} id={idx}>{item.slice(1,)}</div>
                                            </> : <div className={"room-rec reverse"} id={idx}>{item}</div>
                                        })}
                                    </div>
                                    {/*<div className="tip">
                                        * 안마의자 커버가 있는 경우 목/어깨 안마를 제대로 느끼실 수 없습니다. 커버를 모두 벗겨낸 후 사용하시는 것을 권장드립니다. 또한, 사용 후 안마의자를 원위치 해주시고 꼭 전원을 모두 꺼주시길 바랍니다. (일부 안마의자는 마사지가 끝나도 자동으로 원위치되지않음)
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                    </BrowserView>
                    <MobileView>
                        <div className="time">
                            {branchList.map((b, i) => <div className={branch == b ? "rec-branch on" : "rec-branch"}
                                                           id={b} onClick={() => {
                                setBranch(b);
                                setRoom("1")
                            }}>{branchDict[b]}</div>)}
                        </div>
                        <div className="img-row">
                            <div className="txt-box">
                                <div className="txt-lg">오시는 길</div>
                            </div>
                        </div>
                        <div className="img-row">
                            <div className="img-sm-mobile width">
                                <img src={process.env.PUBLIC_URL + '/img/store/' + branch + '/가게전면_위.webp'}
                                     alt="헤드텍스트가 들어갑니다"/>
                            </div>
                            <div className="img-sm-mobile width">
                                <img src={process.env.PUBLIC_URL + '/img/store/' + branch + '/가게측면.webp'}
                                     alt="헤드텍스트가 들어갑니다"/>
                            </div>
                        </div>

                        <div className="txt-box">
                            <div
                                className="desc">{branchAddress[branch][0] + " (" + branchAddress[branch][1] + ")"}</div>
                            {/*<div className="desc">{"("+branchAddress[branch][1]+")"}</div>*/}
                        </div>
                        <div className="img-row"/>
                        <div className="img-row">
                            <div className="txt-box">
                                <div className="txt-lg">안마의자 소개</div>
                            </div>
                        </div>
                        <div className="date">
                            <IoIosArrowBack style={{marginRight: 20}} onClick={subRoom}/>
                            {"ROOM " + room}
                            <IoIosArrowForward style={{marginLeft: 20}} onClick={addRoom}/>
                        </div>

                        <div className="img-row">
                            <div className="img-sm">
                                <img src={process.env.PUBLIC_URL + "/img/chair/" + branch + "/" + roomImg}
                                     alt="각 안마의자 이미지"/>
                            </div>
                        </div>
                        <div className="img-row mobile">
                            <div className="txt-box mobile">
                                <div className="form-group mobile">
                                    <label htmlFor="id"
                                           className={"rec-label " + RoomRank[branch][room]}>{RoomRank[branch][room]} </label>
                                </div>

                                <div className="txt-md">{RoomName[branch][room]}</div>
                                <div className="val">
                                    <div className="desc">
                                        마사지 세기 :
                                        {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["strength"])))]).map((n, i) =>
                                            <TbStar color="FFB137"/>)}
                                        {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["strength"] % 1)))]).map((n, i) =>
                                            <TbStarHalf color="FFB137"/>)}
                                        {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                    </div>
                                    <div className="desc">
                                        안마의자 크기 :
                                        {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["size"])))]).map((n, i) =>
                                            <TbStar color="FFB137"/>)}
                                        {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["size"] % 1)))]).map((n, i) =>
                                            <TbStarHalf color="FFB137"/>)}
                                        {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                    </div>
                                </div>
                                {(RoomExplain[branch][room]["etc"]).map((item, idx) => {
                                    return <div className="desc">- {item}</div>
                                })}
                                <div className="time">
                                    {(RoomExplain[branch][room]["mode"]).map((item, idx) => {
                                        return (item[0]) == "*" ? <><br/>
                                            <div className={"room-rec on"} id={idx}>{item.slice(1,)}</div>
                                        </> : <div className={"room-rec reverse"} id={idx}>{item}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </MobileView>

                    {/*<div className="img-row">
                        <div className="txt-box">
                            <div className="txt-lg">편의시설 소개</div>
                        </div>
                    </div>*/}

                    <div className="img-col">
                        {/*<div className="img-row">
                        <div className="img-sm width">
                            <img src={process.env.PUBLIC_URL + "/img/facility/각방조명.webp"} alt="각 안마의자 이미지"/>
                            <div className="txt-box">
                                <div className="desc right">- 수면에 적합하도록 각 방에 조명을 개별로 설치하였습니다. </div>
                            </div>
                        </div>
                        <div className="img-sm width">
                            <img src={process.env.PUBLIC_URL + "/img/facility/안대.png"} alt="각 안마의자 이미지"/>
                            <div className="txt-box">
                                <div className="desc right">- 수면시 사용하실 수 있는 안대를 각 방에 준비했습니다. </div>
                            </div>
                        </div>
                        <div className="img-sm width">
                            <img src={process.env.PUBLIC_URL + "/img/facility/공기청정기.webp"} alt="각 안마의자 이미지"/>
                            <div className="txt-box">
                                <div className="desc right">- 공기청정기를 통해 맑고 깨끗한 공기를 제공합니다.</div>
                            </div>
                        </div>

                    </div>*/}
                        {/*<div className="img-row">
                        <div className="img-sm width">
                            <img src={process.env.PUBLIC_URL + "/img/facility/산소발생기.webp"} alt="각 안마의자 이미지"/>
                            <div className="txt-box">
                                <div className="desc right">- 산소발생기와 디퓨저로 산소가득 쾌적한 공기를 제공합니다.</div>
                            </div>
                        </div>
                        <div className="img-sm width">
                            <img src={process.env.PUBLIC_URL + "/img/facility/충전기.webp"} alt="각 안마의자 이미지"/>
                            <div className="txt-box">
                                <div className="desc right">- 편의를 위해 각 방에 충전기가 구비되어있습니다.</div>
                            </div>
                        </div>
                        <div className="img-sm width">
                            <img src={process.env.PUBLIC_URL + "/img/facility/소화기.webp"} alt="각 안마의자 이미지"/>
                            <div className="txt-box">
                                <div className="desc right">- 안전을 위해 각 방과 복도에 소화기가 구비되어 있습니다.</div>
                            </div>
                        </div>
                    </div>*/}
                    </div>
                </div>}

            </div>
            <MobileDetailMenu/>
        </>);
};

export default Intro;
