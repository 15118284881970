import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, branchList, branchDict, rebranchDict, roomList, numList, saleDict } from "./config.js"
import { TbStar, TbStarHalf } from "react-icons/tb";
import { BrowserView, MobileView } from 'react-device-detect'
import * as moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';


const BookingRoom = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [room, setRoom] = useState("1");
    const [showB, setShowB] = useState(false);

    const location = useLocation();
    console.log(location)
    if (location.state == null) {
        window.location.href = "/book"
    }

    const [branch, setBranch] = useState(sessionStorage.getItem('branch')==null?location.state.branch:sessionStorage.getItem('branch'));

    const today = new Date();
    const [date, setDate] = useState(today);

    const [showCalendar, setShowCalendar] = useState(false);
    const [nowDate, setNowDate] = useState(new Date());
    const [yearPicked, setYearPicked] = useState(nowDate.getFullYear());
    const [monthPicked, setMonthPicked] = useState(nowDate.getMonth());
    const [datePicked, setDatePicked] = useState(nowDate.getDate());
    const [calendarDate, setCalendarDate] = useState("");
    const [calendarDates, setCalendarDates] = useState([]);
    const [monthDates, setMonthDates] = useState(new Date(nowDate.getFullYear(), nowDate.getMonth()-1, 0).getDate());


    function getDatesList() {
        var calendarDatesList = ['일','월','화','수','목','금','토']
        for (let i=1; i<36; i++){
            if (i<=calendarDate){
                calendarDatesList.push("")
            }else if(i-calendarDate<=monthDates){
                calendarDatesList.push(i-calendarDate)
            }else{
                calendarDatesList.push("")
            }
        }
        setCalendarDates(calendarDatesList)
    }

    useEffect(() => {

        if (yearPicked==nowDate.getFullYear() && monthPicked==nowDate.getMonth()) {
            setDatePicked(nowDate.getDate())
        } else {
            setDatePicked(1)
        }
        setMonthDates(new Date(yearPicked, monthPicked, 0).getDate())
        setCalendarDate(((new Date(yearPicked, monthPicked, datePicked).getDay()-datePicked%7+1) < 0? (new Date(yearPicked, monthPicked, datePicked).getDay()-datePicked%7+1+7) : (new Date(yearPicked, monthPicked, datePicked).getDay()-(datePicked)%7+1)))
//        setDate(new Date(yearPicked, monthPicked, datePicked))
    }, [yearPicked, monthPicked, nowDate])

    useEffect(() => {
//        setDatePicked(nowDate.getDate())
        setDate(new Date(yearPicked, monthPicked, datePicked))
    }, [showCalendar])

    useEffect(() => {
        setMonthDates(new Date(yearPicked, monthPicked, 0).getDate())
        getDatesList()
        console.log("calendarDate: ", calendarDate)
    }, [calendarDate])

    const handleYearPlus = () => {
        if (yearPicked==nowDate.getFullYear()){
            setYearPicked(prev=>prev+1);
        }else{
            window.alert('경매시작일을 현재일로부터 1개일 이상의 일자로는 설정하실 수 없습니다.')
        }
    }
    const handleYearMinus = () => {
        if (yearPicked==nowDate.getFullYear()+1 && nowDate.getMonth()<monthPicked){
            setYearPicked(prev=>prev-1);
        }if (yearPicked==nowDate.getFullYear()+1 && nowDate.getMonth()>=monthPicked){
            setMonthPicked(nowDate.getMonth());
            setYearPicked(prev=>prev-1);
        }else{
            window.alert('예약일을 현재일보다 과거일로는 설정하실 수 없습니다.')
        }
    }
    const handleMonthPlus = () => {
        console.log(nowDate.getMonth())
        console.log(monthPicked)
        if (yearPicked==nowDate.getFullYear() && (monthPicked-nowDate.getMonth())>=1) {
            window.alert('예약일을 현재일로부터 1개월 이상의 일자로는 설정하실 수 없습니다.')
        } else if (monthPicked<11){
            setMonthPicked(prev=>prev+1);
        } else if(monthPicked==11 && yearPicked==nowDate.getFullYear()){
            setMonthPicked(prev=>prev+1-12);
            setYearPicked(prev=>prev+1);
        } else{
            window.alert('예약일을 현재일로부터 1개월 이상의 일자로는 설정하실 수 없습니다.')
        }
    }
    const handleMonthMinus = () => {
        if (monthPicked>0 && nowDate.getMonth()<monthPicked && yearPicked==nowDate.getFullYear()){
            setMonthPicked(prev=>prev-1);
        }else if(monthPicked>0 && yearPicked==nowDate.getFullYear()+1){
            setMonthPicked(prev=>prev-1);
        }else if(monthPicked==0 && yearPicked==nowDate.getFullYear()+1){
            setMonthPicked(prev=>prev-1+12);
            setYearPicked(prev=>prev-1);
        }else{
            window.alert('예약일을 현재일보다 과거일로는 설정하실 수 없습니다.')
        }
    }
    const handleDate = (e) => {
        if (monthPicked==nowDate.getMonth() && yearPicked==nowDate.getFullYear() && e.target.value<nowDate.getDate()) {
            window.alert('예약일을 현재일보다 과거일로는 설정하실 수 없습니다.')
        } else if (monthPicked>nowDate.getMonth() && yearPicked>=nowDate.getFullYear() && e.target.value>nowDate.getDate()) {
            window.alert('예약일은 최대 한달까지 설정하실 수 있습니다.')
        } else if (e.target.value!='') { // && !(monthPicked==nowDate.getMonth() && yearPicked==nowDate.getFullYear() && e.target.value<nowDate.getDate())&& !(monthPicked>nowDate.getMonth() && yearPicked>=nowDate.getFullYear() && e.target.value>nowDate.getDate())){
            setDatePicked(e.target.value)
        }
    }


    const handleClose = () => setShow(false);
    const handleCloseB = () => setShowB(false);
    const handleShow = (event) => {
        console.log("ROOM::",event.target.id);
        setRoom(event.target.id);
        setShow(true);
    };
    const handleBranch = (event) => {
        console.log("handleBranch")
        console.log(event.target.id)
        setRoom("1")
        setBranch(event.target.id);
        sessionStorage.setItem('branch', event.target.id)
        setShowB(false)
    };

    const showBranchMobile = (event) => {
        console.log("handleBranch")
        setShowB(true)
//        setBranch(event.target.id);
//        sessionStorage.setItem('branch', event.target.id)
    };


    const [hourNow, setHourNow] = useState(today.getHours());
    const [hour, setHour] = useState(today.getHours());
    const [day, setDay] = useState(0);
    const [select, setSelect] = useState([]);
    const [reserved, setReserved] = useState({
        "booking": [],
        "paid": [],
        "bookingMargin": [],
        "paidMargin": []
    });

    const [start, setStart] = useState("9_9_9");
    const [end, setEnd] = useState("0_0_0");
    const [step, setStep] = useState(1);
    const [price, setPrice] = useState(0);

    function getReservedBlock() {
        console.log(process.env.REACT_APP_serviceUrl + `/reservations?branch=${branch}&base_date=${moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","")}&base_hour=${hour}`)
        fetch(process.env.REACT_APP_serviceUrl + `/reservations?branch=${branch}&base_date=${moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","")}&base_hour=${hour}`, {
            credentials: 'include',
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            }).then(res => res.json())
            .then(json => {
                if (json.status=="success"){
                    setReserved(json.data)
                    console.log("getReservedBlock")
                    console.log(json.data)
                }
                else {
                    console.log("ERROR OCCURED")
//                    window.location.reload();
                }
                console.log(json.data);

            })
    }

    const deleteRoom = () => {
        fetch(process.env.REACT_APP_serviceUrl + "/reservation"//stgUrl + "/reservation"
        , {
            credentials: 'include',
            method: "DELETE",
            headers: {
                  "Content-Type": "application/json",
            },
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status=="success") {
                    console.log("예약내역 삭제 성공")
                } else {
                    console.log("예약내역 삭제 실패")
                }

            })
    }

    useEffect(() => {
        console.log(location.state)
        if (location.state!=null){
            console.log("정상적인 접근")
        }
        else {
            window.alert("비정상적인 접근입니다. 처음부터 예약을 진행해주세요.")
            window.location.href = "/book"
        }

        console.log(window.location.href)
        console.log(window.location.href.slice(-4,))
        if (window.location.href.slice(-4,)=="book"){
            deleteRoom()
        }
        getReservedBlock()

    }, [])


    useEffect(() => {
        getReservedBlock()
    }, [hour, branch])

    useEffect(() => {
        console.log("date 변경" )
        console.log(day)
        console.log(date)
        console.log(nowDate)
        console.log("date 변경 222" )
        console.log(date.getTime()-nowDate.getTime())
        console.log(Math.floor((date.getTime()-nowDate.getTime())/(1000*60*60*24)+1))
        setDay(Math.floor((date.getTime()-nowDate.getTime())/(1000*60*60*24)+1))
        if ((date.getDate()-nowDate.getDate())==0){
            setHour(today.getHours())
        }else {
            setHour(0)
        }
    }, [date])

    {/*useEffect(() => {
        console.log("day 변경====================" )
        console.log(date)
        console.log(day)
        if (day==0){
            setHour(today.getHours())
        }else {
            setHour(0)
        }
    }, [day])*/}



    const addDate = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        console.log("day", day)
        if (day>31){
            window.alert("최대 한달 이후까지만 예약이 가능합니다.")
        } else {
            console.log("prev date: ", date)
            let newDate = new Date(date)
            newDate.setDate(newDate.getDate() + 1)
            console.log("changed date: ", newDate)
            setDate(newDate);
            setDay(prev=>prev+1)

            if (day==-1){
                setHour(today.getHours())
            }else {
                setHour(0)
            }

        }
    };
    const subDate = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        console.log("day", day)
        if (day>0){
            console.log("prev date: ", date)
            let newDate = new Date(date)
            newDate.setDate(newDate.getDate() - 1)
            console.log("changed date: ", newDate)
            console.log("day is : ", day)
            setDate(newDate);
            setDay(prev=>prev-1)

            if (day==1){
                setHour(today.getHours())
            }else {
                setHour(0)
            }
        }else{
            console.log("현재보다 이전날짜로는 예약하실 수 없습니다.")
            window.alert("현재보다 이전날짜로는 예약하실 수 없습니다.")
        }
    };

    const handleHour = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        if (event.target.id=="-2"){
            setHour(prev=>prev-2)
        }else if (event.target.id=="-1"){
            setHour(prev=>prev-1)
        }else if (event.target.id=="+1"){
            setHour(prev=>prev+1)
        }else if (event.target.id=="+2"){
            setHour(prev=>prev+2)
        }
    };

    const handleHour2 = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        console.log("handleHour2: ", event.target.id)
        setHour(prev=>prev+parseInt(event.target.id))
    };

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
        }


    function calcPrice() {
        const hour = parseInt(end.split("_")[1]) - parseInt(start.split("_")[1])
        const minutes = parseInt(end.split("_")[2]) - parseInt(start.split("_")[2])
        const time = hour*60 + (minutes+1)*10
        console.log("hour: ", hour)
        console.log("minutes: ", minutes)
        console.log("time: ", time)
        const nhour = Math.floor(time/60)
        const nhalf = Math.floor((time-60*nhour)/30)
        const nmin = Math.floor((time-60*nhour-30*nhalf)/10)
        const roomPrice = nhour * RankPrice[RoomRank[branch][start.split("_")[0]]][60] + nhalf * RankPrice[RoomRank[branch][start.split("_")[0]]][30] + nmin * RankPrice[RoomRank[branch][start.split("_")[0]]][10]
        setPrice(roomPrice)
    }

    const handleSelect = (event) => {
        console.log(event.target)
        console.log(event.target.className)

        if (reserved["paid"].includes(event.target.id)) {
            window.alert("이미 예약된 시간은 선택하실 수 없습니다.")
        } else if (reserved["booking"].includes(event.target.id)) {
            window.alert("선택하신 시간은 다른 고객님께서 예약을 진행중입니다.")
        } else if (reserved["paidMargin"].includes(event.target.id)) {
            window.alert("선택하신 시간은 다른 예약과의 여유시간 입니다.")
        } else if (reserved["bookingMargin"].includes(event.target.id)) {
            window.alert("선택하신 시간은 다른 예약과의 여유시간 입니다.")
        } else if (event.target.className=="mini-rec off") {
            window.alert("선택하신 시간은 이미 지난 시간이어서 예약이 불가능합니다.")
        } else if (event.target.className=="mini-rec over" && branch=="Yeoksam") {
            window.alert("선택하신 시간은 영업시간이 아닙니다. (영업시간은 6:00-24:00 입니다.)")
        } else {
            const roomNum = parseInt((event.target.id).split("_")[0])
            const timeNum = parseInt((event.target.id).split("_")[1] + (event.target.id).split("_")[2])
            console.log(roomNum)
            if (start=="9_9_9"){
                setStart(event.target.id)
                setEnd(event.target.id)
            } else if (roomNum==start.split("_")[0]){
                if (end==start){
                    if (event.target.id>start){
                        let pass = false
                        for (var res=0; res<reserved["paid"].length; res++) {
                            console.log("res:",reserved["paid"][res],"--","start:",start,"--","end:",event.target.id)
                            if (reserved["paid"][res]>start && reserved["paid"][res]<event.target.id) {
                                pass=true
                            }
                        }
                        if (pass==false) {
                            for (var res=0; res<reserved["booking"].length; res++) {
                                console.log("res:",reserved["booking"][res],"--","start:",start,"--","end:",event.target.id)
                                if (reserved["booking"][res]>start && reserved["booking"][res]<event.target.id) {
                                    pass=true
                                }
                            }
                        }
                        if (pass==false) {
                            setEnd(event.target.id)
                        } else {
                            window.alert("이미 예약된 시간을 포함하여 예약하실수는 없습니다.")
                        }
                    } else {
                        setStart(event.target.id)
                        setEnd(event.target.id)
                    }
                } else {
                    setStart(event.target.id)
                    setEnd(event.target.id)
                }
            } else {
                setStart(event.target.id)
                setEnd(event.target.id)
            }

        }
    };

    useEffect(() => {
        calcPrice()
    }, [start, end])

    const nextStep = (event) => {
        console.log(hour)
        let moveNext= false;
        if (start=="9_9_9" && end=="0_0_0") {
            window.alert("예약하고자 하는 방과 시간을 선택해주세요.")
        } else if (start.split("_")[1]=="1" && Math.ceil(today.getMinutes()/10)>=parseInt(start.split("_")[2]) && hour==today.getHours() && day==0) {
            console.log(today.getMinutes())
            console.log(Math.ceil(today.getMinutes()/10))
            console.log(parseInt(start.split("_")[2]))
            console.log(start)
            var warning = window.confirm("예약을 요청하신 시간이 이미 "+today.getMinutes()%10+"분 이상 지난 시간입니다. 그럼에도 예약을 진행하시겠습니까?")
            if (warning) {
                moveNext = true
            }
        } else {
            moveNext = true
        }
        if (moveNext) {
            let startTime = "";
            let endTime = "";
            console.log("test: start")
            console.log((hour+parseInt(start.split('_')[1])-1))
            if ((hour+parseInt(start.split('_')[1])-1)<=23) {
                if ((hour+parseInt(start.split('_')[1])-1)>9){
                    startTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + (hour+parseInt(start.split('_')[1])-1).toString() + ((parseInt(start.split('_')[2])-1).toString()+"0");
                } else {
                    startTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(start.split('_')[1])-1).toString() + ((parseInt(start.split('_')[2])-1).toString()+"0");
                }
            } else {
                const addStDate = new Date(date)
                addStDate.setDate(addStDate.getDate()+1)
                startTime = moment(addStDate, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(start.split('_')[1])-1-24).toString() + ((parseInt(start.split('_')[2])-1).toString()+"0");
            }

            if ((hour+parseInt(end.split('_')[1])-1)<=23) {
                if ((hour+parseInt(end.split('_')[1])-1)>9){
                    endTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + (hour+parseInt(end.split('_')[1])-1).toString() + ((parseInt(end.split('_')[2])-1).toString()+"0");
                } else {
                    endTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(end.split('_')[1])-1).toString() + ((parseInt(end.split('_')[2])-1).toString()+"0");
                }
            } else {
                const addDate = new Date(date)
                addDate.setDate(addDate.getDate()+1)
                endTime = moment(addDate, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(end.split('_')[1])-1-24).toString() + ((parseInt(end.split('_')[2])-1).toString()+"0");
            }

            console.log("startTime:",startTime)
            console.log("endTime:",endTime)
            console.log({
                    "branch": branch,
                    "room": start.split("_")[0],
                    "start_date_time": startTime,
                    "end_date_time": endTime,
                    })
//            window.alert("선택하신 시간,룸은 5분동안 점유됩니다. 5분내로 예약을 부탁드립니다:)")

            fetch(process.env.REACT_APP_serviceUrl + "/reservation", {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "branch": branch,
                    "room": start.split("_")[0],
                    "start_date_time": startTime,
                    "end_date_time": endTime,
                    }
                )
                }).then(res => res.json())
                .then(json => {
                    console.log(json)

                    if (json.status=="success") {
                        setStep(prev => prev+1)
                        navigate( '/info',
                        { state: {
                                  branch:branch,
                                  start:start,
                                  end:end,
                                  date:date,
                                  startTime:startTime,
                                  endTime:endTime,
                                  price:Math.floor(price*(1-saleDict[branch]['percent'])*0.1)*10,
                                  name:"",
                                  email:"",
                                  phone:""
                                    } } )
                    } else {
                        window.alert(json.message)
                    }

                })
            }
    }



    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <BrowserView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> STEP 1 : 지점 선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> STEP 2 : 방 선택 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="2"> STEP 3 : 예약 정보 </div>
                        <div className={step>=3? "rectangle on":"rectangle"} id="3"> STEP 4 : 요금 결제 </div>
                        <div className={step>=4? "rectangle right on":"rectangle right"} id="4"> STEP 5 : 예약완료 </div>
                    </BrowserView>
                    <MobileView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> 1. 지점선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> 2. 방선택 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="2"> 3. 정보 </div>
                        <div className={step>=3? "rectangle on":"rectangle"} id="3"> 4. 결제 </div>
                        <div className={step>=4? "rectangle right on":"rectangle right"} id="4"> 5. 완료 </div>
                    </MobileView>
                    {/*<hr/>*/}
                    <div>
                        <div className="date center">
                            <IoIosArrowBack className="date subDate arrow" onClick={subDate} />
                            {/*<label htmlFor="id" className="label">*/}
                            <label className="date subDate left" onClick={subDate}> 이전날짜 </label>
                            <a onClick={()=>setShowCalendar(true)}>{date.getMonth()+1}월 {date.getDate()}일</a>
                            <label className="date subDate right" onClick={addDate}> 다음날짜 </label>
                            <IoIosArrowForward className="date subDate arrow" onClick={addDate} />
                        </div>
                        {/*<div className="time"><IoIosArrowBack style={{marginRight : 20}} /> 16시 <IoIosArrowForward style={{marginLeft : 20}} /></div>*/}
                        <div className="time">
                            <div className="card-list">
                                <div>
                                    {day==0?
                                        [...Array(Math.min(2,(hour-hourNow)))].map((_, i)=>(
                                            <div className={(hourNow-hour+i-1)==0?"time-rec on":"time-rec"} id={i-Math.min(2,(hour-hourNow))} onClick={handleHour2}>{hour+(i-Math.min(2,(hour-hourNow)))}시</div>
                                        ))
                                    :
                                        [...Array(Math.min(2,hour))].map((_, i)=>(
                                            <div className={i==hour?"time-rec on":"time-rec"} id={i-Math.min(2,hour)} onClick={handleHour2}>{hour+(i-Math.min(2,hour))}시</div>
                                        ))
                                    }
                                    {/*<div className="time-rec on" id={0} onClick={handleHour2}>{hourNow}시</div>
                                    <div className="time-rec" id={1} onClick={handleHour2}>{hourNow+1}시</div>
                                    <div className="time-rec" id={2} onClick={handleHour2}>{hourNow+2}시</div>
                                    <div className="time-rec" id={3} onClick={handleHour2}>{hourNow+3}시</div>
                                    <div className="time-rec" id={4} onClick={handleHour2}>{hourNow+4}시</div>
                                    <div className="time-rec" id={5} onClick={handleHour2}>{hourNow+5}시</div>
                                    <div className="time-rec" id={6} onClick={handleHour2}>{hourNow+6}시</div>*/}
                                    {/*<div className="time-rec" id={0} onClick={handleHour2}>{hourNow}시</div>*/}
                                    {day==0?
                                        [...Array((Math.min((24-hour), (10-(Math.min(2,(hour-hourNow)))))))].map((_, i)=>(
                                            <div className={i==0?"time-rec on":"time-rec"} id={i} onClick={handleHour2}>{hour+i}시</div>
                                        ))
                                    :
                                        [...Array((Math.min((24-hour), (10-(Math.min(2,hour))))))].map((_, i)=>(
                                            <div className={i==0?"time-rec on":"time-rec"} id={i} onClick={handleHour2}>{hour+i}시</div>
                                        ))
                                    }
                                    {/*<div className="time-rec on" id={0} onClick={handleHour2}>{hourNow}시</div>
                                    <div className="time-rec" id={1} onClick={handleHour2}>{hourNow+1}시</div>
                                    <div className="time-rec" id={2} onClick={handleHour2}>{hourNow+2}시</div>
                                    <div className="time-rec" id={3} onClick={handleHour2}>{hourNow+3}시</div>
                                    <div className="time-rec" id={4} onClick={handleHour2}>{hourNow+4}시</div>
                                    <div className="time-rec" id={5} onClick={handleHour2}>{hourNow+5}시</div>
                                    <div className="time-rec" id={6} onClick={handleHour2}>{hourNow+6}시</div>*/}
                                </div>
                                {/*{day==0 && hour==hourNow? <div className="time-rec on" id="-2" onClick={handleHour}>{hourNow}시</div> : <div className="time-rec" id="-2" onClick={handleHour}>{hourNow}시</div>:<div className="time-rec" id="-2" onClick={handleHour}>{hour-2}시</div>}
                                {day==0 && hour-1<hourNow? <div className="time-rec" id="-1" onClick={handleHour}>{hourNow+1}시</div>:<div className="time-rec" id="-1" onClick={handleHour}>{hour-1}시</div>}
                                {hour>23 || hour<0? <div onClick={handleHour} /> : <div className="time-rec on" onClick={handleHour}>{hour}시</div>}
                                {hour+1>23 || hour+1<0? <div onClick={handleHour} id="+1" /> :<div className="time-rec" onClick={handleHour} id="+1">{hour+1}시</div>}
                                {hour+2>23 || hour+2<0? <div onClick={handleHour} id="+2" /> :<div className="time-rec" onClick={handleHour} id="+2">{hour+2}시</div>}*/}
                            </div>

                        </div>
                        {/*<div className="time">
                            <div className="card-list">
                                {day==0 && hour-2<hourNow || hour-2>23 || hour-2<0? <div onClick={handleHour} id="-2" />:<div className="time-rec" id="-2" onClick={handleHour}>{hour-2}시</div>}
                                {day==0 && hour-1<hourNow || hour-1>23 || hour-1<0? <div onClick={handleHour} id="-1" />:<div className="time-rec" id="-1" onClick={handleHour}>{hour-1}시</div>}
                                {hour>23 || hour<0? <div onClick={handleHour} /> : <div className="time-rec on" onClick={handleHour}>{hour}시</div>}
                                {hour+1>23 || hour+1<0? <div onClick={handleHour} id="+1" /> :<div className="time-rec" onClick={handleHour} id="+1">{hour+1}시</div>}
                                {hour+2>23 || hour+2<0? <div onClick={handleHour} id="+2" /> :<div className="time-rec" onClick={handleHour} id="+2">{hour+2}시</div>}
                            </div>
                        </div>*/}
                        {/*<div className="title">사용하실 방을 선택해주세요.</div>*/}
                        <BrowserView>
                            <div className="form-group right">
                                <div className="flat-ticket-blue"onClick={()=>window.location.href="/recommend"}> {/*onClick={()=>window.location.href="/recommend"*/}
                                    안마의자 추천받기
                                </div>
                                <div className="flat-ticket" onClick={()=>window.alert("해당 기능은 오픈 예정입니다. 조금만 기다려주세요:)")}>
                                    정액권 결제하기
                                </div>
                            </div>
                            <div className="form-group right">
                                {branch=="Ku"?
                                    RankList.map((rank, i)=>
                                    (<div>
                                        <label htmlFor="id" className={"rec-label right "+rank}>{rank} </label>
                                        <label htmlFor="id" className="sub-label right">{"10분: "+addComma(RankPrice[rank][10])+"원 / 30분: "+addComma(RankPrice[rank][30])+"원 / 1시간: "+addComma(RankPrice[rank][60])+"원"} </label>
                                    </div>)
                                    )
                                    :
                                    RankList.slice(0,-1).map((rank, i)=>
                                        (<div>
                                            <label htmlFor="id" className={"rec-label right "+rank}>{rank} </label>
                                            <label htmlFor="id" className="sub-label right">{"10분: "+addComma(RankPrice[rank][10])+"원 / 30분: "+addComma(RankPrice[rank][30])+"원 / 1시간: "+addComma(RankPrice[rank][60])+"원"} </label>
                                        </div>)
                                    )
                                }
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="form-group right bottom">
                                {branch=="Ku"?
                                    RankList.map((rank, i)=>
                                    (<div>
                                        <label htmlFor="id" className={"rec-label mobile right "+rank}>{rank} </label>
                                        <label htmlFor="id" className="sub-label mobile right">{"10분: "+addComma(RankPrice[rank][10])+"원 / 30분: "+addComma(RankPrice[rank][30])+"원 / 1시간: "+addComma(RankPrice[rank][60])+"원"} </label>
                                    </div>)
                                    )
                                    :
                                    RankList.slice(0,-1).map((rank, i)=>
                                        (<div>
                                            <label htmlFor="id" className={"rec-label mobile right "+rank}>{rank} </label>
                                            <label htmlFor="id" className="sub-label mobile right">{"10분: "+addComma(RankPrice[rank][10])+"원 / 30분: "+addComma(RankPrice[rank][30])+"원 / 1시간: "+addComma(RankPrice[rank][60])+"원"} </label>
                                        </div>)
                                    )
                                }
                            </div>
                        </MobileView>
                        <BrowserView>
                        {branchList.map((b, i)=>
                            <div className={branch==b? "line-rec up on": "line-rec up"} id={b} onClick={handleBranch}> {branchDict[b]} </div>
                         )}
                        </BrowserView>
                        <MobileView>
                            <div>
                                <div className={"line-rec up on"} id={branch} onClick={showBranchMobile}> {branchDict[branch]} </div>
                            </div>
                        </MobileView>
                        <hr className="line" />
                        {/*<span className="tip">* 하나의 방에 대해서는 한 타임만 예약이 가능합니다.</span>*/}

                        {roomList[branch].map((n, i)=>(
                        <div className="form-group">
                            <div>
                                <label htmlFor="id" className={"rec-label "+RoomRank[branch][n]}>{RoomRank[branch][n]} </label>
                                <label htmlFor="id" className="label">{"ROOM "+n+" : "+RoomName[branch][n]}</label>
                                <label className="label mini" onClick={handleShow} id={n} >
                                    자세히
                                </label>
                                {/*<label htmlFor="id" className="label right">{"ROOM "+n+" : "+RoomName[branch][n]}</label>*/}
                                {/*<div>*/}
                                <div className="card-list">
                                    <div className="item">
                                        <label htmlFor="id" className="sub-label">{hour}시 </label>
                                        <div>
                                            {numList[branch].slice(0,6).map((num, idx)=>(
                                                <div className={((day==0 && hourNow==hour && Math.round(today.getMinutes()/10)>idx) || (branch=="Yeoksam" && (hour>=24 || hour<=5)))? "mini-rec off":((start<=(n+"_1_"+num)&&end>=(n+"_1_"+num))? "mini-rec click": (reserved["paid"].includes(n+"_1_"+num)?"mini-rec red": (reserved["booking"].includes(n+"_1_"+num)?"mini-rec redLight": (reserved["paidMargin"].includes(n+"_1_"+num)?"mini-rec green":(reserved["bookingMargin"].includes(n+"_1_"+num)?"mini-rec greenLight":"mini-rec")))))} id={n+"_1_"+num} onClick={handleSelect}/>
                                            ))}
                                        </div>
                                    </div>
                                    {numList[branch].slice(1,5).map((number, index)=>(
                                        <div className="item">
                                            <label htmlFor="id" className="sub-label">{hour+parseInt(number)-1}시 </label>
                                            <div>
                                                <div className={(branch=="Yeoksam" && ((hour+index)>=23 || (hour+index)<5))? "mini-rec over" : (start<=(n+"_"+number+"_1")&&end>=(n+"_"+number+"_1"))? "mini-rec blank click": (reserved["paid"].includes(n+"_"+number+"_1")?"mini-rec blank red":(reserved["booking"].includes(n+"_"+number+"_1")?"mini-rec blank redLight":(reserved["paidMargin"].includes(n+"_"+number+"_1")? "mini-rec green blank": (reserved["bookingMargin"].includes(n+"_"+number+"_1")? "mini-rec greenLight blank": "mini-rec blank"))))} id={n+"_"+number+"_1"} onClick={handleSelect} />
                                                {numList[branch].slice(1,6).map((num)=>(
                                                    <div className={(branch=="Yeoksam" && ((hour+index)>=23 || (hour+index)<5))? "mini-rec over" : (start<=(n+"_"+number+"_"+num)&&end>=(n+"_"+number+"_"+num))? "mini-rec click": (reserved["paid"].includes(n+"_"+number+"_"+num)? "mini-rec red": (reserved["booking"].includes(n+"_"+number+"_"+num)? "mini-rec redLight": (reserved["paidMargin"].includes(n+"_"+number+"_"+num)? "mini-rec green":(reserved["bookingMargin"].includes(n+"_"+number+"_"+num)? "mini-rec greenLight":"mini-rec"))))} id={n+"_"+number+"_"+num} onClick={handleSelect} />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <label htmlFor="id" className="margin"/>
                            </div>
                        </div>))}
                        <span className="calc-fixed">
                            <div>
                                예약장소 : <span className="calc highlight">{start=="9_9_9"?"" : ("Room "+start.split("_")[0])}</span>
                            </div>
                            <div>
                                예약일시 : {start=="9_9_9"? <span /> : <span className="calc highlight">2024년 {date.getMonth()+1}월 {date.getDate()}일 {hour+parseInt(start.split("_")[1])-1}:{start.split("_")[2]=="1"?"00":((parseInt(start.split("_")[2])-1)*10)} - {hour+parseInt(end.split("_")[1])+Math.floor(end.split("_")[2]/6)-1}:{end.split("_")[2]=="6"?"00":(parseInt(end.split("_")[2]))*10}</span>}
                            <div>
                            </div>
                                결제하실 금액 :
                                {price<0?
                                    <span />
                                    :
                                    <span className="calc highlight" style={{color : '#FF426F', textDecoration:"line-through", fontSize:"18px"}}>
                                        {price<0? "0": addComma(price)}원{" "}
                                    </span>
                                }
                                <span className="calc highlight">
                                    {price<0? "0": addComma(Math.floor(price*(1-saleDict[branch]['percent'])*0.1)*10)}원
                                </span>

                            </div>
                            <div>
                                {price<0?
                                    <span />
                                    :
                                    <span className="calc highlight" style={{color : '#7F7F93'}}>
                                        {"("+saleDict[branch]['reason']+")"}
                                    </span>
                                    }
                            </div>
                            <div className="btn-wrap">
                                <button className="btn btn-black" onClick={nextStep}>다음으로</button>
                            </div>
                            <div className="txt-underline" onClick={() => {window.location.href = "/book"}}>
                                이전단계로
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <Modal className="modal-white" show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleClose}><i className="ico ico-modal-close" /></button>
                <div className="content">
                    <div className="title"><div className="form-group"><label htmlFor="id" className={"rec-label "+RoomRank[branch][room]}>{RoomRank[branch][room]} </label> {"ROOM "+room+" : "+RoomName[branch][room]}</div></div>
                    <div className="desc">{RoomExplain[branch][room]["desc"]}</div>
                    <div className="box">
                        <div className="val">
                            {/*상세 설명*/}
                            <div className="val">
                                <div className="items">
                                    마사지 세기 :
                                    {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                    {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["strength"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                    {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                </div>
                                <div className="items">
                                    안마의자 크기 :
                                    {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                    {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["size"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                    {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                </div>
                                <div className="items">
                                    특화 기능 : {RoomExplain[branch][room]["special"]}
                                </div>
                                <div className="items">
                                    그 외 특징 :
                                    {RoomExplain[branch][room]["etc"].map((line, idx)=>
                                    <div className="item">
                                        - {line}
                                    </div>
                                    )}
                                </div>
                                <div className="items">
                                    가격 :
                                    <div className="item">
                                        {" 10분: "+addComma(RankPrice[RoomRank[branch][room]][10])+"원 / 30분: "+addComma(RankPrice[RoomRank[branch][room]][30])+"원 / 1시간: "+addComma(RankPrice[RoomRank[branch][room]][60])+"원"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tip"><span className="color-false">*</span> 안마의자에 대한 느낌은 주관적일 수 있으니 선택시 참고만 해주세요.</div>
                    <div className="btn-wrap">
                        <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleClose}>닫기</button>
                    </div>
                </div>
            </Modal>
            <Modal className="modal-white" show={showB} onHide={handleCloseB} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleCloseB}><i className="ico ico-modal-close" /></button>
                <div className="content">
                    <div className="title"><div className="form-group"> 지점 선택하기</div></div>
                    <div className="box">
                        <div className="val">
                            {branchList.map((b, i)=>
                                <button className="btn btn-orange-mini" id={b} onClick={handleBranch}> {branchDict[b]} </button>
                             )}
                            {/*branchList.map((b, i)=>
                                <div className={branch==b? "line-rec up on": "line-rec up"} id={b} onClick={handleBranch}> {branchDict[b]} </div>
                             )*/}

                        </div>
                    </div>
                    {/*<div className="btn-wrap">
                        <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleCloseB}>닫기</button>
                    </div>*/}
                </div>
            </Modal>
            <Modal className="modal-calendar" show={showCalendar} onHide={()=>setShowCalendar(false)} centered animation={false}>
                <button className="btn-modal-close" onClick={()=>setShowCalendar(false)}><i className="ico ico-modal-close" /></button>
                <div className="content">
                    <div className="title">예약하실 날짜를 선택해 주세요</div>
                    {/*<div className="desc">날짜를 선택해 주세요</div>*/}
                    <div className="calendar-ui">
                        <div className="calendar-head">
                            {nowDate.getMonth()==11?
                                <div className="item">
                                    <button className="btn-nav" onClick={handleYearMinus}><i className="ico ico-prev-grey"/></button>
                                    <span className="value">{yearPicked+"년"}</span>
                                    <button className="btn-nav" onClick={handleYearPlus}><i className="ico ico-next-grey"/></button>
                                </div>
                                :
                                <div className="item">
                                    <span className="value">{yearPicked+"년"}</span>
                                </div>
                            }
                            <div className="item">
                                <button className="btn-nav" onClick={handleMonthMinus}><i className="ico ico-prev-grey"/></button>
                                <span className="value">{monthPicked+1+"월"}</span>
                                <button className="btn-nav" onClick={handleMonthPlus}><i className="ico ico-next-grey"/></button>
                            </div>
                        </div>
                        <div className="calendar-body">
                            {monthPicked==new Date().getMonth() && yearPicked==new Date().getFullYear()?
                            (calendarDates.map((date)=>
                                <button className={date==datePicked? "day on" : date<new Date().getDate()? "day old":"day"} onClick={handleDate} value={date}>{date}</button>))
                            :(calendarDates.map((date)=>
                                <button className={date==datePicked? "day on" : date>new Date().getDate()? "day old":"day"} onClick={handleDate} value={date}>{date}</button>))
                            }
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <button className="btn btn-black" onClick={()=>setShowCalendar(false)}>선택</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default BookingRoom;


