import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {branchDict} from "../config.js"
import {BrowserView, MobileView} from 'react-device-detect'

const Infos = () => {
    console.log(null)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [room, setRoom] = useState("1");

    const location = useLocation();
    console.log(location)
    const [data, setData] = useState(location.state);
    console.log(data)
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const today = new Date();
    const [date, setDate] = useState(null);
    const [hourNow, setHourNow] = useState(today.getHours());
    const [hour, setHour] = useState(today.getHours());
    const [day, setDay] = useState(0);
    const [select, setSelect] = useState([]);
    const [reserved, setReserved] = useState([]);
    const [branch, setBranch] = useState("");

//    const [history, setHistory] = useState([]);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [step, setStep] = useState(2);
    const [price, setPrice] = useState(null);
    const [roomList, setRoomList] = useState(["6", "4", "2", "5", "3", "7", "8", "1", "9"]);
    const [numList, setNumList] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9"]);

    useEffect(() => {
        if (data == null) {
            prevStep()
            window.alert("비정상적인 접근입니다. 처음부터 예약을 진행해주세요.")
            navigate('/admin', {state: {branch: data.branchList}})
        } else {
            setName(data.name)
            setEmail(data.email)
            setPhone(data.phone)
            setDate(data.date)
            setStart(data.start)
            setEnd(data.end)
            setStartTime(data.startTime)
            setEndTime(data.endTime)
            setPrice(data.price)
            setOpen(true)
            setBranch(data.branch)
        }
    }, [])


    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        console.log("ROOM::", event.target.id);
        setRoom(event.target.id);
        setShow(true);
    };


    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }

    const prevStep = (event) => {
        {/* window.location.href = '/admin/book' */
        }
        navigate('/admin/book', {state: {branchList: data.branchList}})
    }

    const payStep = (event) => {
        if (name == "") {
            window.alert("이름을 입력해주세요.")
        } else if (name.length < 2) {
            window.alert("이름은 한글자이상 입력해주세요.")
        } else if (phone == "") {
            window.alert("휴대폰 번호를 입력해주세요.")
        } else if (phone.length < 10) {
            window.alert("정상적인 휴대폰 번호를 입력해주세요.")
        } else if (email.length > 0 && (email.length < 5 || email.includes("@") == false || email.includes(".") == false)) {
            window.alert("정상적인 이메일을 입력해주세요")
        } else {
            console.log({
                "branch": branch,
                "room": start.split("_")[0],
                "startDateTime": startTime,
                "endDateTime": endTime,
                "amount": price,
                "orderName": ("room" + (start.split("_")[0]) + "(" + startTime + " ~ " + endTime + ")"),
                "name": name,
                "mobileNum": phone,
                "email": email,
                "bankCode": "06"
            })

            let data = {}

            if (email == "" || email == null || email == " " || email.length < 5 || email.includes("@") == false) {
                data = {
                    "branch": branch,
                    "room": start.split("_")[0],
                    "startDateTime": startTime,
                    "endDateTime": endTime,
                    "amount": price,
                    "orderName": ("room" + (start.split("_")[0]) + "(" + startTime + " ~ " + endTime + ")"),
                    "name": name,
                    "mobileNum": phone,
                    "bankCode": "06"
                }
            } else {
                data = {
                    "branch": branch,
                    "room": start.split("_")[0],
                    "startDateTime": startTime,
                    "endDateTime": endTime,
                    "amount": price,
                    "orderName": ("room" + (start.split("_")[0]) + "(" + startTime + " ~ " + endTime + ")"),
                    "name": name,
                    "mobileNum": phone,
                    "email": email,
                    "bankCode": "06"
                }
            }
            fetch(process.env.REACT_APP_serviceUrl + "/admin/virtual-accounts", {
                credentials: 'include', method: "POST", headers: {
                    "Content-Type": "application/json",
                }, body: JSON.stringify(data)
            }).then(res => res.json())
                .then(json => {
                    console.log(json)
                    if (json.status == "success") {
                        window.alert("성공적으로 가상계좌를 발급했습니다.")
                    } else {
                        window.alert(json.message)
                    }
                })
        }
    }


    return (<>
        <div className="mypage-wrap">
            <div className="inner">
                {/*<BrowserView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> STEP 1 : 지점 선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> STEP 2 : 방 선택 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="3"> STEP 3 : 예약 정보 </div>
                        <div className={step>=3? "rectangle on":"rectangle"} id="4"> STEP 4 : 요금 결제 </div>
                        <div className={step>=4? "rectangle right on":"rectangle right"} id="5"> STEP 5 : 예약완료 </div>
                    </BrowserView>
                    <MobileView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> 1. 지점선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> 2. 방선택 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="3"> 3. 정보 </div>
                        <div className={step>=3? "rectangle on":"rectangle"} id="4"> 4. 결제 </div>
                        <div className={step>=4? "rectangle right on":"rectangle right"} id="5"> 5. 완료 </div>
                    </MobileView>*/}

                <div>
                    <div className="form-box">
                        {!open ? <div / >
                            :
                            <>
                                <div className="form-group">
                                    <span className="tip title">예약 정보</span>
                                    <div>
                                        예약장소 : <span
                                        className="calc highlight">{start == "9_9_9" ? "" : (branchDict[branch] + " - Room " + start.split("_")[0])}</span>
                                    </div>
                                    <div>
                                        예약일시 : {start == "9_9_9" ? <span/> : <span
                                        className="calc highlight">2024년 {data.startTime.slice(4, 6)}월 {data.startTime.slice(6, 8)}일 {data.startTime.slice(8, 10)}:{data.startTime.slice(10, 12)} ~ 2024년 {data.endTime.slice(4, 6)}월 {data.endTime.slice(6, 8)}일 {(parseInt(data.endTime.slice(10, 12)) + 10) == 60 ? (parseInt(data.endTime.slice(8, 10)) + 1).toString() : data.endTime.slice(8, 10)}:{(parseInt(data.endTime.slice(10, 12)) + 10) == 60 ? "00" : (parseInt(data.endTime.slice(10, 12)) + 10).toString()}</span>}
                                        <div>
                                        </div>
                                        금액 : <span
                                        className="calc highlight">{price < 0 ? "0" : addComma(price)}원</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <span className="tip title">예약자 정보</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name" className="label"><span
                                        className="color-false">* </span>이름</label>
                                    <input className="form-control" type="text" id="name" placeholder="이름을 입력해주세요."
                                           value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="tel2" className="label"><span className="color-false">* </span> 전화번호</label>
                                    {/*<div className="form-with-btn">
                                        <input className="form-control" type="text" id="tel2" placeholder="010-7748-0152" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                        {/*<button className="btn-send" type="button">인증번호 보내기</button>
                                    </div>*/}
                                    <input className="form-control" type="text" id="tel"
                                           value={phone.length > 7 ? phone.slice(0, 3) + "-" + phone.slice(3, 7) + "-" + phone.slice(7,) : phone.length > 3 ? phone.slice(0, 3) + "-" + phone.slice(3,) : phone}
                                           onChange={(e) => setPhone(e.target.value.toString().replaceAll("-", ""))}
                                           placeholder="전화번호를 입력해 주세요"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="id" className="label">이메일</label>
                                    <div className="tip">* 이메일은 필수입력값이 아니지만 입력시 출입문 링크가 카카오톡 뿐만 아니라 이메일로도 발송이됩니다.</div>
                                    <input className="form-control" type="text" id="id" placeholder="이메일을 입력해주세요."
                                           value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                            </>}
                            <div className="form-group">
                                <span className="tip title">환불 규정</span>
                                <span className="tip">예약시간 <span className="tip bold">30분전 </span>까지 : <span
                                    className="tip important">100% 환불 </span></span>
                                <span className="tip">예약시간 <span className="tip bold">30분전 </span>이후 :  <span
                                    className="tip important">환불 불가 </span> </span>
                                <span
                                    className="tip">(단, 고장이나 기타 판매자의 과실로 인해 서비스를 정상적으로 받으실 수 없는 경우에는 전액 환불하여 드립니다.) </span>
                            </div>
                            {/*<div className="form-group">
                                <label htmlFor="number" className="label">인증번호</label>
                                <div className="form-with-btn">
                                    <input className="form-control" type="text" id="number" placeholder="인증번호를 입력해 주세요"/>
                                    <button className="btn-send color-black" type="button">02:00</button>
                                </div>
                            </div>*/}
                            <div>
                                <BrowserView>
                                    {/*<div className="btn-wrap">
                                      <button className="btn btn-black left" onClick={prevStep}>이전단계</button>*/}
                                    <button className="btn btn-black right" onClick={payStep}>다음으로</button>
                                    <div className="txt-underline" onClick={prevStep}>
                                        이전단계로
                                    </div>
                                    {/*} </div>*/}
                                </BrowserView>
                                <MobileView>
                                    {/*<button className="btn btn-black left prev" onClick={prevStep}>이전단계</button>*/}
                                    <button className="btn btn-black right" onClick={payStep}>다음으로</button>
                                    <div className="txt-underline" onClick={prevStep}>
                                        이전단계로
                                    </div>
                                </MobileView>
                            </div>
                        </div>
                            </div>
                            </div>
                            </div>
                            </>
                            );};

                        export default Infos;


