import { chairExplain } from "./data.js"

export const addComma = (price) => {
    let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return returnString;
}
export const branchList = ["Gwanghwamun","Yeouido","Ku","Yeoksam"] // "Yeoksam"
export const branchDict = {
    "Gwanghwamun":"광화문점",
    "Yeouido":"여의도점",
    "Ku":"고대안암점",
    "Yeoksam":"역삼점"
}
export const rebranchDict = {
    "광화문점":"Gwanghwamun",
    "여의도점":"Yeouido",
    "고대안암점":"Ku",
    "역삼점":"Yeoksam"
}

export const branchAddress = {
    "Gwanghwamun": [
        "르메이에르 종로 지하2층 올라가는 방향 에스컬레이터 옆",
        "서울 종로구 종로 19 르메이에르종로타운 지하 2층"
    ],
    "Yeouido": [
        "하남빌딩 1층 교동전선생/디저트39 옆",
        "서울특별시 영등포구 여의도동 의사당대로1길 25 1층 116호"
    ],
     "Ku": [
        "용초수 / 카페 별채건물 지하 1층",
        "서울 성북구 고려대로27길 20 지하1층"
    ],
    "Yeoksam": [
        "이너스타워 (아리스타 건물) 지하 1층",
        "서울 강남구 테헤란로21길 5 지하1층"],
}

export const branchWIFI = {
    "Gwanghwamun": [
        "nabijam-gwanghwamun5G",
        "(비밀번호 : nabijam202404)"
    ],
    "Yeouido": [
        "nabijam-yeouido5G",
        "(비밀번호 : nabijam202407)"
    ],
    "Ku": [
        "nabijam-ku 5GHz",
        "(비밀번호 : nabijam1234)"
    ],
    "Yeoksam": [
        "nabijam-yeoksam 5GHz",
        "(비밀번호 : nabijam63)"
    ]
}

export const branchETC = {
    "Gwanghwamun": [
        "복도에 설치된 휴대폰을 통해서 직접 주차처리 가능하십니다.",
        "최대 2시간 무료주차 가능하십니다. (단, 다른 르메이에르 상가와 중복적용은 어렵습니다.)"
    ],
    "Yeouido": [
        "상가 주차장은 유료입니다. (주말/공휴일에는 운영하지 않음)",
        "건물 방침상 방문고객님들에게도 유료로 운영하고 있다고합니다. (근처 주차장을 이용해주세요. 불편드려서 죄송합니다.)"
    ],
    "Ku": [
        "건물 내 주차장이 없어 주차는 불가합니다.",
        "주변 유료 주차장을 이용해주세요."
    ],
    "Yeoksam": [
        "건물 내 주차장이 없어 주차는 불가합니다.",
        "주변 유료 주차장을 이용해주세요."
    ],
}

export const saleDict = {
    "Gwanghwamun": {
        "percent": 0.05,
        "reason" : "웹사이트 전용 5% 할인적용"
    },
    "Yeouido": {
        "percent": 0.05,
        "reason" : "웹사이트 전용 5% 할인적용"
    },
    "Ku": {
        "percent": 0.05,
        "reason" : "웹사이트 전용 5% 할인적용"
    },
    "Yeoksam": {
        "percent": 0.05,
        "reason" : "웹사이트 전용 5% 할인적용"
    },
}

export const roomList = {
    "Gwanghwamun":["6","4","2","8","5","1","3","7","9"],
    "Yeouido":["8","4","9","10","7","6","2","3","1","5"],
    "Ku":["9","2","3","4","1","6","7","5","8","10"],
    "Yeoksam":["2","3","1","4", "5","11","12","13","14","6","7","8","9","10"],
}

export const numList = {
    "Gwanghwamun":["1","2","3","4","5","6","7","8","9"],
    "Yeouido":["1","2","3","4","5","6","7","8","9","10"],
    "Ku":["1","2","3","4","5","6","7","8","9","10"],
    "Yeoksam":["1","2","3","4","5","6","7","8","9","10","11","12","13","14"],
}

export const roomImgDict = {
    "Gwanghwamun":
    {
        "Room 6" : "오씸_유러브2.webp",
        "Room 5" : "바디프랜드_아제라.webp",
        "Room 3" : "바디프랜드_팬텀.webp",
        "Room 7" : "바디프랜드_구팬텀.webp",
        "Room 8" : "바디프랜드_아제라.webp",
        "Room 4" : "바디프랜드_셀레네2.webp",
        "Room 1" : "제스파_루넉스.webp",
        "Room 2" : "코지마_카이저.webp",
        "Room 9" : "코지마_코지체어.webp",
    },
    "Yeouido":
    {
        "Room 8" : "파나소닉_EP-MAF1.webp",
        "Room 4" : "오씸_유러브2.webp",
        "Room 9" : "바디프랜드_팬텀2.webp",
        "Room 10" : "바디프랜드_팬텀2_2.webp",
        "Room 7" : "코지마_카이저.webp",
        "Room 6" : "바디프랜드_셀레네2.webp",
        "Room 2" : "바디프랜드_엘리자베스.webp",
        "Room 3" : "바디프랜드_아제라.webp",
        "Room 5" : "코지마_코지체어.webp",
        "Room 1" : "코지마_오브제.webp",
    },
    "Ku":
    {
        "Room 9" : "오씸_유러브2.webp",
        "Room 2" : "코지마_카이저.webp",
        "Room 3" : "코지마_트론.webp",
        "Room 4" : "바디프랜드_팬텀2.webp",
        "Room 1" : "바디프랜드_구팬텀.webp",
        "Room 6" : "바디프랜드_엘리자베스.webp",
        "Room 7" : "바디프랜드_아제라.webp",
        "Room 5" : "코지마_클라쎄.webp",
        "Room 8" : "코지마_시에스타.webp",
        "Room 10" : "제스파_컴포르테.webp",
    },
    "Yeoksam":
    {
        "Room 2" : "오씸_유러브2.webp",
        "Room 3" : "오씸_유러브.webp",
        "Room 1" : "오씸_유디바인.webp",
        "Room 4" : "바디프랜드_팬텀2.webp",
        "Room 5" : "바디프랜드_팬텀2골드.webp",
        "Room 11" : "바디프랜드_팬텀2.webp",
        "Room 12" : "바디프랜드_팬텀2.webp",
        "Room 13" : "바디프랜드_팬텀2.webp",
        "Room 14" : "바디프랜드_팬텀2.webp",
        "Room 6" : "바디프랜드_셀레네2.webp",
        "Room 7" : "바디프랜드_샹그리라.webp",
        "Room 8" : "바디프랜드_렉스엘.webp",
        "Room 9" : "바디프랜드_코닉.webp",
        "Room 10" : "바디프랜드_레그넘.webp",
    }

}

export const RoomName = {
    "Gwanghwamun":
        {
            "6" : "오씸 \"유러브2\"",
            "4" : "바디프랜드 \"셀레네2\"",
            "2" : "코지마 \"카이저\"",
            "5" : "바디프랜드 \"아제라\"",
            "3" : "바디프랜드 \"팬텀\"",
            "7" : "바디프랜드 \"팬텀\"",
            "8" : "바디프랜드 \"아제라\"",
            "1" : "제스파 \"루넉스\"",
            "9" : "코지마 \"코지체어\"",
        },
    "Yeouido":
        {
            "8" : "파나소닉 \"EP-MAF1\"",
            "4" : "오씸 \"유러브2\"",
            "9" : "바디프랜드 \"팬텀2\"",
            "10" : "바디프랜드 \"팬텀2\"",
            "7" : "코지마 \"카이저\"",
            "6" : "바디프랜드 \"셀레네2\"",
            "2" : "바디프랜드 \"엘리자베스\"",
            "3" : "바디프랜드 \"아제라\"",
            "5" : "코지마 \"코지체어\"",
            "1" : "코지마 \"오브제\"",
        },
    "Ku":
        {
            "9" : "오씸 \"유러브2\"",
            "2" : "코지마 \"카이저\"",
            "3" : "코지마 \"트론\"",
            "4" : "바디프랜드 \"팬텀2\"",
            "1" : "바디프랜드 \"팬텀\"",
            "6" : "바디프랜드 \"엘리자베스\"",
            "7" : "바디프랜드 \"아제라\"",
            "5" : "코지마 \"클라쎄\"",
            "8" : "코지마 \"시에스타\"",
            "10" : "제스파 \"컴포르테\"",
        },
    "Yeoksam":
        {
            "2" : "오씸 \"유러브\"",
            "3" : "오씸 \"유러브\"",
            "1" : "오씸 \"유디바인\"",
            "4" : "바디프랜드 \"팬텀2\"",
            "5" : "바디프랜드 \"팬텀2골드\"",
            "11" : "바디프랜드 \"팬텀2\"",
            "12" : "바디프랜드 \"팬텀2\"",
            "13" : "바디프랜드 \"팬텀2\"",
            "14" : "바디프랜드 \"팬텀2\"",
            "6" : "바디프랜드 \"셀레네2\"",
            "7" : "바디프랜드 \"샹그리라\"",
            "8" : "바디프랜드 \"렉스엘\"",
            "9" : "바디프랜드 \"코닉\"",
            "10" : "바디프랜드 \"레그넘\"",
        }
 }

export const RankList = ["Superb", "Premium", "Gold", "Standard", "Normal"]
export const RankColor =  {
                            "Superb":"#FF14EA",
                            "Premium":"#3D8FEA",
                            "Gold":"#E9A91",
                            "Standard":"#AF7E56",
                            "Normal":"#006400",

                            }

export const RankPrice = {
    "Superb" : {60 : 15000, 30 : 8500, 10 : 3000},
    "Premium" : {60 : 12500, 30 : 7000, 10 : 2500},
    "Gold" : {60 : 10000, 30 : 5500, 10 : 2000},
    "Standard" : {60 : 7000, 30 : 4000, 10 : 1500},
    "Normal" : {60 : 4500, 30 : 2500, 10 : 1000},
}

export const RoomRank = {
    "Gwanghwamun": {
        "6" : "Superb",
        "4" : "Premium",
        "2" : "Premium",
        "5" : "Gold",
        "3" : "Gold",
        "7" : "Gold",
        "8" : "Gold",
        "1" : "Gold",
        "9" : "Standard",
    },
    "Yeouido": {
        "8" : "Superb",
        "4" : "Superb",
        "9" : "Premium",
        "10" : "Premium",
        "7" : "Premium",
        "6" : "Gold",
        "2" : "Gold",
        "3" : "Gold",
        "5" : "Standard",
        "1" : "Standard",
    },
    "Ku": {
        "9" : "Superb",
        "2" : "Premium",
        "3" : "Premium",
        "4" : "Premium",
        "1" : "Gold",
        "6" : "Gold",
        "7" : "Gold",
        "5" : "Standard",
        "8" : "Normal",
        "10" : "Normal",
    },
    "Yeoksam": {
        "2" : "Superb",
        "3" : "Superb",
        "1" : "Premium",
        "4" : "Premium",
        "5" : "Premium",
        "11" : "Premium",
        "12" : "Premium",
        "13" : "Premium",
        "14" : "Premium",
        "6" : "Gold",
        "7" : "Gold",
        "8" : "Gold",
        "9" : "Standard",
        "10" : "Standard",
    }

 }

 export const RoomExplain = {
    "Gwanghwamun": {
        "6" : chairExplain["오씸-유러브2"],
        "4" : chairExplain["바디프랜드-셀레네2"],
        "2" : chairExplain["코지마-카이저"],
        "5" : chairExplain["바디프랜드-아제라"],
        "3" : chairExplain["바디프랜드-팬텀"],
        "7" : chairExplain["바디프랜드-팬텀"],
        "8" : chairExplain["바디프랜드-아제라"],
        "1" : chairExplain["제스파-루넉스"],
        "9" : chairExplain["코지마-코지체어"],
    },
    "Yeouido": {
        "8" : chairExplain["파나소닉-EP-MAF1"],
        "4" : chairExplain["오씸-유러브2"],
        "9" : chairExplain["바디프랜드-팬텀2"],
        "10" : chairExplain["바디프랜드-팬텀2"],
        "7" : chairExplain["코지마-카이저"],
        "6" : chairExplain["바디프랜드-셀레네2"],
        "2" : chairExplain["바디프랜드-엘리자베스"],
        "3" : chairExplain["바디프랜드-아제라"],
        "5" : chairExplain["코지마-코지체어"],
        "1" : chairExplain["코지마-오브제"],
    },
    "Ku": {
        "9" : chairExplain["오씸-유러브2"],
        "2" : chairExplain["코지마-카이저"],
        "3" : chairExplain["코지마-트론"],
        "4" : chairExplain["바디프랜드-팬텀2"],
        "1" : chairExplain["바디프랜드-팬텀"],
        "6" : chairExplain["바디프랜드-엘리자베스"],
        "7" : chairExplain["바디프랜드-아제라"],
        "5" : chairExplain["코지마-클라쎄"],
        "8" : chairExplain["코지마-시에스타"],
        "10" : chairExplain["제스파-컴포르테"],
    },
    "Yeoksam": {
        "2" : chairExplain["오씸-유러브2"],
        "3" : chairExplain["오씸-유러브"],
        "1" : chairExplain["오씸-유디바인"],
        "4" : chairExplain["바디프랜드-팬텀2"],
        "5" : chairExplain["바디프랜드-팬텀2"],
        "11" : chairExplain["바디프랜드-팬텀2"],
        "12" : chairExplain["바디프랜드-팬텀2"],
        "13" : chairExplain["바디프랜드-팬텀2"],
        "14" : chairExplain["바디프랜드-팬텀2"],
        "6" : chairExplain["바디프랜드-셀레네2"],
        "7" : chairExplain["바디프랜드-샹그리라"],
        "8" : chairExplain["바디프랜드-렉스엘"],
        "9" : chairExplain["바디프랜드-코닉"],
        "10" : chairExplain["바디프랜드-레그넘"],
    },
 }

export const QnAs = [
    {
        "Q":"이메일이나 휴대폰 번호를 잘못 입력했어요.",
        "A":"\"조회\"탭에서 예약내역을 조회하신 후 \"문열기\" 버튼을 이용해주세요. 혹시나 둘다 잘못된 경우, 고객센터에 문의해주세요."
    },
    {
        "Q":"문열기 링크를 클릭했는데 문이 안열려요.",
        "A":"도어락 신호가 조금 느리게 갈 수 있습니다. 2-3번 누르시고 10초이상 기다리셔도 안되시는 경우 고객센터에 문의해주세요."
    },
    {
        "Q":"예약한 안마의자가 고장이 났어요.",
        "A":"전송된 설명대로 진행해보았는데도 안되시는 경우, 고객센터에 문의해주세요."
    },
    {
        "Q":"외부 음식이 반입 가능한가요?",
        "A":"음식을 안마의자 기기에 흘리시는 경우 고장의 원인이 될 수 있기 때문에 외부음식 반입은 금지하고 있으며, 이를 지키지 않아 발생한 고장 시에 손해배상이 청구될 수 있습니다."
    },
    {
        "Q":"환불을 받고 싶습니다.",
        "A":" 예약한 시간 30분전까지는 100% 환불을 해드리고 있습니다. \n“조회”탭에서 예약하신 휴대폰 번호를 인증하신 후 예약내역을 선택하여 환불받으실 수 있습니다. \n그 시간이 지난 후의 환불은 원칙적으로 불가하나, 단순 변심이 아니라 시스템 고장등의 판매자과실로 인한 경우 고객센터로 문의주시면 환불 조치 도와드리겠습니다."
    },
]
export const BankCode = [
    { label : "KB국민은행", value: "06" },
    { label : "신한은행", value: "88" },
    { label : "NH농협은행", value: "11" },
    { label : "단위농협(지역농축협)", value: "12" },
    { label : "우리은행", value: "20" },
    { label : "하나은행", value: "81" },
    { label : "카카오뱅크", value: "90" },
    { label : "케이뱅크", value: "89" },
    { label : "토스뱅크", value: "92" },
    { label : "새마을금고", value: "45" },
    { label : "신협", value: "48" },
    { label : "IBK기업은행", value: "03" },
    { label : "KDB산업은행", value: "02" },
    { label : "씨티은행", value: "27" },
    { label : "SC제일은행", value: "27" },
    { label : "Sh수협은행", value: "07" },
    { label : "부산은행", value: "23" },
    { label : "DGB대구은행", value: "31" },
]

export const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#D59B53',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#D59B53',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

export const steps = [
  {
    id: '1',
    message: '안녕하세요, 나비잠안마의자입니다. 무엇을 도와드릴까요?',
    trigger: '1Opt',
  },
  {
    id: '1Opt',
    options: [
      { value: 1, label: '예약/결제', trigger: '1Opt-1' },
      { value: 2, label: '환불/오류/고장', trigger: '1Opt-2' },
      { value: 3, label: '가맹상담', trigger: '1Opt-3' },
      { value: 4, label: '상담원 연결', trigger: '1Opt-4' },
    ],
  },
  {
    id: '1Opt-1',
    message: '예약/결제 문의입니다. 어떤 문제가 있으신가요?',
    trigger: '1Opt-1Opt',
  },

  {
    id: '1Opt-1Opt',
    options: [
      { value: 1, label: '예약방법', trigger: '1Opt-1Opt-1' },
      { value: 2, label: '결제오류', trigger: '1Opt-1Opt-2' },
      { value: 3, label: '예약내역확인', trigger: '1Opt-1Opt-3' },
      { value: 4, label: '상담원 연결', trigger: '1Opt-4' },
    ],
  },
  {
    id: '1Opt-1Opt-1',
    message: 'www.nabi-jam.com/book 웹에서 "예약/결제" 탭을 통해 예약을 진행해주세요. 혹시 원활하게 진행이 되지 않으신다면 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: 'Final'
  },
  {
    id: '1Opt-1Opt-2',
    message: '결제시 다양한 원인으로 인해 오류가 발생할 수 있어요. 여러번 시도시에도 안될 경우 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: 'Final'
  },
  {
    id: '1Opt-1Opt-3',
    message: 'www.nabi-jam.com/check 웹에서 "조회" 탭을 통해 예약을 진행해주세요',
    trigger: 'Final'
  },
  {
    id: '1Opt-1Opt-4',
    component: (
        <div onClick={()=> window.location.href="http://pf.kakao.com/_gdGNG"}> 1:1 카카오톡 채널 상담 바로가기 </div>
      ),
    trigger: 'Final'
  },

  {
    id: '1Opt-2',
    message: '환불/오류/고장 문의입니다. 어떤 문제가 있으신가요?',
    trigger: '1Opt-2Opt',
  },
  {
    id: '1Opt-2Opt',
    options: [
      { value: 1, label: '환불방법', trigger: '1Opt-2Opt-1' },
      { value: 2, label: '문열기오류', trigger: '1Opt-2Opt-2' },
      { value: 3, label: '안마의자고장', trigger: '1Opt-2Opt-3' },
      { value: 4, label: '상담원 연결', trigger: '1Opt-4' },
    ],
  },
  {
    id: '1Opt-2Opt-1',
    message: 'www.nabi-jam.com/check 웹에서 "조회" 탭을 통해 조회하신 뒤 환불을 진행해주세요. 예약시작시간 30분전까지는 100% 환불이 가능합니다. (그 이후로는 환불불가, 안마의자 고장등의 사유의 경우 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.)',
    trigger: 'Final'
  },
  {
    id: '1Opt-2Opt-2',
    message: '문열기링크 오류시 010-8128-5916으로 전화 또는 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: '1Opt-1Opt-4'
  },
  {
    id: '1Opt-2Opt-3',
    message: '안마의자 고장시 010-8128-5916으로 전화 또는 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: '1Opt-1Opt-4'
  },
  {
    id: '1Opt-2Opt-4',
    message: '010-8128-5916으로 전화 또는 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: '1Opt-1Opt-4'
  },

  {
    id: '1Opt-3',
    message: '가맹상담은 010-8128-5916으로 전화 또는 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: '1Opt-1Opt-4'
  },
  {
    id: '1Opt-4',
    message: '그 외 다른 문의는 010-8128-5916으로 전화 또는 카카오톡 채널 "나비잠안마의자"로 문의부탁드립니다.',
    trigger: '1Opt-1Opt-4'
  },

  {
    id: 'Final',
    options: [
      { value: 1, label: '처음으로', trigger: '1' },
      { value: 2, label: '상담종료', trigger: 'Final-1' },
      { value: 4, label: '상담원 연결', trigger: '1Opt-1Opt-4' },
    ],
  },
  {
    id: 'Final-1',
    message: '상담이 종료되었습니다. 나비잠안마의자를 찾아주셔서 감사합니다.',
    end: true
  },
];