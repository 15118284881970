import React, {useState} from 'react';
import Header from '../Components/Header';
import MobileMenu from "../Components/MobileMenu";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import Intro from "../Pages/Intro";
import Booking from "../Pages/Booking";
import Checking from "../Pages/Checking";
import QnAs from "../Pages/QnAs";


const Main = () => {

    return (<>
            <Header/>
            <div className="main-wrap">
                <Tabs
                    defaultActiveKey=""
                    id="main"
                    onSelect={(k) => window.location.href = "/" + k}
                >
                    <Tab eventKey="" title="소개">
                        <Intro/>
                    </Tab>
                    <Tab eventKey="book" title="예약/결제">
                        <Booking/>
                    </Tab>
                    <Tab eventKey="check" title="조회">
                        <Checking/>
                    </Tab>
                    <Tab eventKey="qna" title="문의">
                        <QnAs/>
                    </Tab>

                </Tabs>
            </div>
            <MobileMenu/>
        </>);
};

export default Main;
