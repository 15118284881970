import React, {useState} from 'react';
import {QnAs} from "./config.js"
import {BrowserView, MobileView} from 'react-device-detect'


const QnA = () => {
    const [show, setShow] = useState(false);
    const [room, setRoom] = useState("1");

    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        console.log("ROOM::", event.target.id);
        setRoom(event.target.id);
        setShow(true);
    };


    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
    }


    return (<>
            <div className="mypage-wrap">
                <div className="inner">
                    <BrowserView>
                        <div className="line-rec up on"> 자주하는 질문</div>
                    </BrowserView>
                    <MobileView>
                        <div className="line-rec-mobile up on"> 자주하는 질문</div>
                    </MobileView>
                    <hr className="line"/>
                    <label htmlFor="id" className={"label"} style={{color: "black", marginLeft: "20px"}}
                           href="http://pf.kakao.com/_gdGNG">카카오톡으로 문의</label>
                    <img className="logo" src={process.env.PUBLIC_URL + '/img/logo/카카오톡 채널_v2.png'} alt="카카오톡 채널"
                         onClick={() => {
                             window.location.href = "http://pf.kakao.com/_gdGNG"
                         }} href="http://pf.kakao.com/_gdGNG"/>
                    <label htmlFor="id" className={"label"} style={{color: "black", marginLeft: "20px"}}
                           href="http://pf.kakao.com/_gdGNG">전화로 문의</label>
                    <img className="logo sm" src={process.env.PUBLIC_URL + '/img/logo/전화연결_v2.png'} alt="전화 연결"
                         onClick={() => {
                             window.location.href = "tel:010-7748-5916"
                         }} href="tel:010-7748-5916"/>
                    <div className="form-group">
                        {QnAs.map((qnaDict, idx) => <div>
                            <label htmlFor="id" className={"label"}
                                   style={{color: "black", marginLeft: "20px"}}> {"Q. " + qnaDict["Q"]} </label>
                            {/*<RiArrowDropDownLine size={30} className={"right"} />*/}
                            <div className="sub-rec"> {qnaDict["A"]} </div>
                            <hr className="sub-line"/>
                        </div>)}
                    </div>
                </div>
            </div>


        </>);
};

export default QnA;



